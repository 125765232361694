import { Suspense, useEffect } from 'react'
import { ReactLazyPreload } from 'utilities/PreloadLinks'
import { Redirect, Route, Switch } from 'react-router-dom'
import { SpinnerOverlay } from 'components/SpinnerOverlay'
import { useActiveFeatureFlags } from 'contexts/SiteConfig'
import { Home } from './Home'
import { setPageTitle } from 'utilities/PageTitle'
import { ORG_REPORTS_VIEW, REPORTS_VIEW, SETTINGS_MANAGE } from 'constants/permissions'

const ReportsDirectory = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'Reports' */ './Home').then((file) => {
    return { default: file.Home }
  })
)

const GeneralLedger = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'GeneralLedger' */ 'screens/GeneralLedger/GeneralLedger').then(
    (file) => {
      return { default: file.GeneralLedger }
    }
  )
)

const VoucherLedger = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'VoucherLedger' */ 'screens/VoucherLedger/VoucherLedger').then(
    (file) => {
      return { default: file.VoucherLedger }
    }
  )
)

const ProjectedMembershipRevenue = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'ProjectedMembershipRevenue' */ 'screens/Reports/reports/ProjectedMembershipRevenue/ProjectedMembershipRevenue'
  ).then((file) => {
    return { default: file.ProjectedMembershipRevenue }
  })
)

const ProjectedMembershipRevenueByMonth = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'ProjectedMembershipRevenueByMonth' */ 'screens/Reports/reports/ProjectedMembershipRevenueByMonth/ProjectedMembershipRevenueByMonth'
  ).then((file) => {
    return { default: file.ProjectedMembershipRevenueByMonth }
  })
)

const DepositLedgerV2 = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'DepositLedgerV2' */ 'screens/DepositLedgerV2/DepositLedgerV2').then(
    (file) => {
      return { default: file.DepositLedgerV2 }
    }
  )
)

const TransactionsList = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'TransactionsList' */ 'screens/TransactionsList/TransactionsList'
  ).then((file) => {
    return { default: file.TransactionsList }
  })
)

const AppointmentsList = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'AppointmentsList' */ 'screens/AppointmentsList/AppointmentsList'
  ).then((file) => {
    return { default: file.AppointmentList }
  })
)

const SessionList = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'SessionList' */ 'screens/SessionList/SessionList').then((file) => {
    return { default: file.SessionList }
  })
)

const PractitionerUtilisationV2 = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PractitionerUtilisation' */ 'screens/Reports/reports/PractitionerUtilisationV2'
  )
)

const PractitionerOccupancyByDateV2 = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PractitionerOccupancyByDate' */ 'screens/Reports/reports/PractitionerOccupancyByDateV2'
  )
)

const RoomOccupancyV2 = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'RoomOccupancy' */ 'screens/Reports/reports/RoomOccupancyV2')
)

const BookableAreaOccupancy = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'BookableAreaOccupancy' */ 'screens/Reports/reports/BookableAreaOccupancy'
  )
)

const TopOfferings = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'TopOfferings' */ 'screens/Reports/reports/TopOfferings/TopOfferings'
  ).then((file) => {
    return { default: file.TopOfferings }
  })
)

const RevenueBySalesChannel = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'RevenueBySalesChannel' */ 'screens/Reports/reports/RevenueBySalesChannel/RevenueBySalesChannel'
  ).then((file) => {
    return { default: file.RevenueBySalesChannel }
  })
)

const RevenueByOrderLabel = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'RevenueByOrderLabel' */ 'screens/Reports/reports/RevenueByOrderLabel/RevenueByOrderLabelV2'
  ).then((file) => {
    return { default: file.RevenueByOrderLabel }
  })
)

const RevenueAllocations = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'RevenueAllocations' */ 'screens/Reports/reports/RevenueAllocations/RevenueAllocations'
  ).then((file) => {
    return { default: file.RevenueAllocations }
  })
)

const RevenueAllocationsV2 = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'RevenueAllocations' */ 'screens/Reports/reports/RevenueAllocations/RevenueAllocationsV2'
  ).then((file) => {
    return { default: file.RevenueAllocationsV2 }
  })
)

const ItemRevenues = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'ItemRevenues' */ 'screens/Reports/reports/ItemRevenues/ItemRevenues')
)

const OrderItems = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'OrderItems' */ 'screens/Reports/reports/OrderItems/OrderItems')
)

const ServiceChargeItems = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'ServiceChargeItems' */ 'screens/Reports/reports/ServiceChargeItems/ServiceChargeItems'
  )
)

const PromoCodeUsageSummary = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PromoCodeUsageSummary' */ 'screens/Reports/reports/PromoCodeUsage/PromoCodeUsageSummary'
  ).then((file) => {
    return { default: file.PromoCodeUsageSummary }
  })
)

const PromoCodeUsageBreakdown = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PromoCodeUsageBreakdown' */ 'screens/Reports/reports/PromoCodeUsage/PromoCodeUsageBreakdown'
  ).then((file) => {
    return { default: file.PromoCodeUsageBreakdown }
  })
)

const DiscountTypeUsageSummary = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'DiscountTypeUsageSummary' */ 'screens/Reports/reports/DiscountTypeUsage/DiscountTypeUsageSummary'
  ).then((file) => {
    return { default: file.DiscountTypeUsageSummary }
  })
)

const DiscountTypeUsageBreakdown = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'DiscountTypeUsageBreakdown' */ 'screens/Reports/reports/DiscountTypeUsage/DiscountTypeUsageBreakdown'
  ).then((file) => {
    return { default: file.DiscountTypeUsageBreakdown }
  })
)

const CancellationsAndNoShows = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'CancellationsAndNoShows' */ 'screens/Reports/reports/CancellationsAndNoShows'
  ).then((file) => {
    return { default: file.CancellationsAndNoShows }
  })
)

const TopCustomers = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'TopCustomers' */ 'screens/Reports/reports/TopCustomers/TopCustomers'
  ).then((file) => {
    return { default: file.TopCustomers }
  })
)

const CheckIns = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'CheckIns' */ 'screens/Reports/reports/CheckIns')
)

const GantnerAudit = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'GantnerAudit' */ 'screens/Reports/reports/GantnerAudit')
)

const DepositLedger = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'DepositLedger' */ 'screens/Reports/reports/DepositLedger')
)

const LedgerLines = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'LedgerLines' */ 'screens/Reports/reports/LedgerLines/LedgerLines'
  ).then((file) => {
    return { default: file.LedgerLines }
  })
)

const TrybePaymentsBalance = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'TrybePaymentsBalance' */ 'screens/Reports/reports/TrybePaymentsBalance'
  )
)

const Payouts = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'Payouts' */ 'screens/Reports/reports/Payouts')
)

const PayoutDetails = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'PayoutDetails' */ 'screens/Reports/reports/Payouts/PayoutDetails')
)

const StockOnHand = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'StockOnHand' */ 'screens/Reports/reports/StockOnHand').then(
    (file) => {
      return { default: file.StockOnHand }
    }
  )
)

const StockAdjustments = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'StockAdjustments' */ 'screens/Reports/reports/StockAdjustments'
  ).then((file) => {
    return { default: file.StockAdjustments }
  })
)

const MembershipRateBreakdown = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'MembershipRateBreakdown' */ 'screens/Reports/reports/MembershipRateBreakdown'
  )
)

const MembershipCharges = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'MembershipCharges' */ 'screens/Reports/reports/MembershipCharges'
  ).then((file) => {
    return { default: file.MembershipCharges }
  })
)

const LedgerLinePostings = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'LedgerLinePostingsReport' */ 'screens/Reports/reports/LedgerLinePostings'
  ).then((file) => {
    return { default: file.LedgerLinePostings }
  })
)

const FiscalReceipts = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'FiscalReceiptsReport' */ 'screens/Reports/reports/FiscalReceipts'
  ).then((file) => {
    return { default: file.FiscalReceipts }
  })
)

const FiscalisationActions = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'FiscalisationActionsReport' */ 'screens/Reports/reports/FiscalisationActions'
  ).then((file) => {
    return { default: file.FiscalisationActions }
  })
)

const SmsUsage = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'SmsUsage' */ 'screens/Reports/reports/SmsUsage/SmsUsage').then(
    (file) => {
      return { default: file.SmsUsage }
    }
  )
)

const IpAddressLog = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'IdAddressLog' */ 'screens/Reports/reports/IpAddressLog/IpAddressLog'
  ).then((file) => {
    return { default: file.IpAddressLog }
  })
)

const OrganisationRevenueBySalesChannel = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'OrganisationRevenueBySalesChannel' */ 'screens/Reports/reports/organisation/OrganisationRevenueBySalesChannel/OrganisationRevenueBySalesChannel'
  ).then((file) => {
    return { default: file.OrganisationRevenueBySalesChannel }
  })
)

const OrganisationRevenueByCentre = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'OrganisationRevenueByCentre' */ 'screens/Reports/reports/organisation/OrganisationRevenueByCentre/OrganisationRevenueByCentre'
  ).then((file) => {
    return { default: file.OrganisationRevenueByCentre }
  })
)

const OrganisationTopOfferings = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'OrganisationTopOfferings' */ 'screens/Reports/reports/organisation/OrganisationTopOfferings/OrganisationTopOfferings'
  ).then((file) => {
    return { default: file.OrganisationTopOfferings }
  })
)

export const ReportsDirectoryRoute = () => {
  return [
    {
      path: '/reports',
      name: 'Reports',
      component: ReportsDirectory,
      exact: true,
      permissions: [REPORTS_VIEW],
    },
  ]
}

export const useRoutes = () => {
  const featureFlags = useActiveFeatureFlags()

  const depositLedgerV2Enabled = featureFlags.includes('deposit_ledger_v2')
  const generalLedgerEnabled = featureFlags.includes('enable_general_ledger_report')
  const newRevenueAllocationsResponseEnabled = featureFlags.includes(
    'revenue_allocations_summary_rewrite'
  )

  return [
    {
      path: '/reports/revenue-by-sales-channel',
      name: 'Revenue by sales channel',
      component: RevenueBySalesChannel,
      exact: true,
      category: 'sales',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/revenue-by-order-label-v2',
      name: 'Revenue by order label',
      component: RevenueByOrderLabel,
      exact: true,
      category: 'sales',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/revenue-allocations-v2',
      name: 'Revenue allocations summary',
      component: RevenueAllocationsV2,
      exact: true,
      category: 'sales',
      permissions: [REPORTS_VIEW],
      hide: newRevenueAllocationsResponseEnabled,
    },
    {
      path: '/reports/revenue-allocations',
      name: 'Revenue allocations summary',
      component: RevenueAllocations,
      exact: true,
      category: 'sales',
      permissions: [REPORTS_VIEW],
      hide: !newRevenueAllocationsResponseEnabled,
    },
    {
      path: '/reports/item-revenues',
      name: 'Revenue allocations breakdown',
      component: ItemRevenues,
      exact: true,
      category: 'sales',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/top-offerings',
      name: 'Item sales summary',
      component: TopOfferings,
      exact: true,
      category: 'sales',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/order-items',
      name: 'Sold items',
      component: OrderItems,
      exact: true,
      category: 'sales',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/service-charge-items',
      name: 'Service charge items',
      component: ServiceChargeItems,
      exact: true,
      category: 'sales',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/promo-code-usage-summary',
      name: 'Promo code usage summary',
      component: PromoCodeUsageSummary,
      exact: true,
      category: 'sales',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/promo-code-usage-breakdown',
      name: 'Promo code usage breakdown',
      component: PromoCodeUsageBreakdown,
      exact: true,
      category: 'sales',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/discount-usage-summary',
      name: 'Discount usage summary',
      component: DiscountTypeUsageSummary,
      exact: true,
      category: 'sales',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/discount-usage-breakdown',
      name: 'Discount usage breakdown',
      component: DiscountTypeUsageBreakdown,
      exact: true,
      category: 'sales',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/no-shows-and-cancellations',
      name: 'Cancellations and no shows',
      component: CancellationsAndNoShows,
      exact: true,
      category: 'sales',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/appointments',
      name: 'Appointments',
      component: AppointmentsList,
      exact: true,
      category: 'sales',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/sessions',
      name: 'Sessions',
      component: SessionList,
      exact: true,
      category: 'sales',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/top-customers',
      name: 'Top 100 customers',
      component: TopCustomers,
      exact: true,
      category: 'customers',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/check-ins',
      name: 'Check-ins',
      component: CheckIns,
      exact: true,
      category: 'customers',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/gantner-audit',
      name: 'Gantner audit',
      component: GantnerAudit,
      exact: true,
      category: 'customers',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/practitioner-utilisation-v2',
      name: 'Practitioner utilisation',
      component: PractitionerUtilisationV2,
      exact: true,
      category: 'occupancy',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/practitioner-occupancy-by-date-v2',
      name: 'Practitioner occupancy by date',
      component: PractitionerOccupancyByDateV2,
      exact: true,
      category: 'occupancy',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/room-occupancy-v2',
      name: 'Room occupancy',
      component: RoomOccupancyV2,
      exact: true,
      category: 'occupancy',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/bookable-area-occupancy',
      name: 'Bookable area occupancy',
      component: BookableAreaOccupancy,
      exact: true,
      category: 'occupancy',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/transactions',
      name: 'Transactions',
      component: TransactionsList,
      exact: true,
      category: 'finance',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/general-ledger',
      name: 'General ledger',
      component: GeneralLedger,
      exact: true,
      category: 'finance',
      hide: !generalLedgerEnabled,
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/voucher-ledger',
      name: 'Voucher ledger',
      component: VoucherLedger,
      exact: true,
      category: 'finance',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/deposit-ledger',
      name: 'Deposit ledger',
      component: DepositLedger,
      exact: true,
      hide: false,
      category: 'finance',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/deposit-ledger-v2',
      name: 'Deposit ledger (v2)',
      component: DepositLedgerV2,
      exact: true,
      category: 'finance',
      hide: !depositLedgerV2Enabled,
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/ledger-lines',
      name: 'Ledger lines',
      component: LedgerLines,
      exact: true,
      category: 'finance',
    },
    {
      path: '/reports/trybe-payments-balance',
      name: 'Trybe payments balance',
      component: TrybePaymentsBalance,
      exact: true,
      // TEMP: hidden until we decide whether to remove this report and just have payouts instead
      hide: true,
      category: 'finance',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/payouts',
      name: 'Payouts',
      component: Payouts,
      exact: true,
      hide: false,
      category: 'finance',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/payouts/:payoutId',
      name: 'Payout details',
      component: PayoutDetails,
      exact: true,
      hide: true,
      category: 'finance',
      permissions: [REPORTS_VIEW],
    },

    // Operations
    {
      path: '/reports/ledger-postings',
      name: 'Ledger postings',
      component: LedgerLinePostings,
      exact: true,
      category: 'operations',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/fiscal-receipts',
      name: 'Fiscal receipts',
      component: FiscalReceipts,
      exact: true,
      category: 'operations',
      permissions: [SETTINGS_MANAGE],
      hide: !featureFlags.includes('fiscalisation'),
    },
    {
      path: '/reports/fiscalisation-actions',
      name: 'Fiscalisation actions',
      component: FiscalisationActions,
      exact: true,
      category: 'operations',
      permissions: [SETTINGS_MANAGE],
      hide: !featureFlags.includes('fiscalisation'),
    },
    {
      path: '/reports/sms-usage',
      name: 'SMS usage',
      component: SmsUsage,
      exact: true,
      category: 'operations',
      permissions: [REPORTS_VIEW],
      hide: !featureFlags.includes('sms_notifications'),
    },
    {
      path: '/reports/organisation/ip-address-log',
      name: 'Login activity',
      component: IpAddressLog,
      exact: true,
      category: 'operations',
      permissions: [ORG_REPORTS_VIEW],
    },

    // Inventory
    {
      path: '/reports/stock-on-hand',
      name: 'Stock on hand',
      component: StockOnHand,
      exact: true,
      category: 'inventory',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/stock-adjustments',
      name: 'Stock adjustments',
      component: StockAdjustments,
      exact: true,
      category: 'inventory',
      permissions: [REPORTS_VIEW],
    },

    // Memberships
    {
      path: '/reports/projected-membership-revenue',
      name: 'Projected revenue summary',
      component: ProjectedMembershipRevenue,
      exact: true,
      category: 'membership',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/projected-membership-revenue-by-month',
      name: 'Projected revenue by month',
      component: ProjectedMembershipRevenueByMonth,
      exact: true,
      category: 'membership',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/membership-rate-breakdown',
      name: 'Membership rate breakdown',
      component: MembershipRateBreakdown,
      exact: true,
      category: 'membership',
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/reports/membership-charges',
      name: 'Membership charges',
      component: MembershipCharges,
      exact: true,
      category: 'membership',
    },

    // Organisation
    {
      path: '/reports/organisation/revenue-by-centre',
      name: 'Revenue allocations summary',
      component: OrganisationRevenueByCentre,
      exact: true,
      category: 'organisation',
    },
    {
      path: '/reports/organisation/revenue-by-sales-channel',
      name: 'Revenue by sales channel',
      component: OrganisationRevenueBySalesChannel,
      exact: true,
      category: 'organisation',
    },
    {
      path: '/reports/organisation/top-offerings',
      name: 'Item sales summary',
      component: OrganisationTopOfferings,
      exact: true,
      category: 'organisation',
    },
  ].filter(Boolean)
}

export const Reports = () => {
  const routes = useRoutes()

  return (
    <>
      <Switch>
        <Route path="/reports" exact={true} component={Home} />
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={(props) => <ReportRoute route={route} {...props} />}
          />
        ))}
        <Redirect from="*" to="/reports" />
      </Switch>
    </>
  )
}

const ReportRoute = ({ route, ...props }) => {
  useEffect(() => {
    if (route.name) {
      setPageTitle(route.name)
    }
  }, [route.name])

  return (
    <Suspense fallback={<SpinnerOverlay />}>
      <route.component {...props} />
    </Suspense>
  )
}
