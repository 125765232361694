import {
  useQuery,
  useMutation,
  useQueryClient,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseMutationResult,
} from 'react-query'
import { SitesApi } from '@trybeapp/sdk.auth'
import { SitesApi as SitesApiTs } from '@trybeapp/sdk'
import { SitesApi as ShopSitesApi } from '@trybeapp/sdk.shop'
import { usePageQueryParam } from 'hooks/UsePageQueryParam'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import { apiConfiguration } from 'utilities/TrybeApiClient'

const api = new SitesApi()
const shopApi = new ShopSitesApi()
const sitesApi = new SitesApiTs(apiConfiguration)

export const useSites = (params = {}, options: UseQueryOptions = {}) => {
  return useQuery(['sites', params], () => api.sitesIndex(params), options)
}

export const usePagedSites = (params = {}) => {
  const [page] = usePageQueryParam()

  return useSites({ page, ...params })
}

export const useGetSite = (siteId, options = {}) => {
  return useQuery(['sites', siteId], () => api.siteShow(siteId), options)
}

export const useGetShopSite = (siteId, options: UseQueryOptions = {}) => {
  options.cacheTime = options.cacheTime ?? Infinity
  options.staleTime = options.staleTime ?? Infinity

  return useQuery(['shopSites', siteId], () => shopApi.shopSiteShow(siteId), options)
}

export const useAddSite = (options: UseMutationOptions = {}) => {
  const queryClient = useQueryClient()

  return useMutation(async (site) => await api.siteStore(site), {
    ...options,
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries(['sites'])
      queryClient.invalidateQueries(['organisations'])

      if (options.onSuccess) {
        options.onSuccess(result, variables, context)
      }
    },
  })
}

export const useSiteLogo = (options) => {
  const siteId = useCurrentSiteId()

  return useQuery(['siteLogo', { siteId }], () => sitesApi.getSiteLogo({ siteId }), options)
}

export const useUpdateSiteLogo = () => {
  const siteId = useCurrentSiteId()
  const queryClient = useQueryClient()

  return useMutation(
    (mediaId: string) =>
      sitesApi.updateSite({
        siteId: siteId,
        updateSite: {
          logo_media_id: mediaId,
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['siteLogo', { siteId }])
      },
    }
  )
}

export const useUpdateSite = (options: UseMutationOptions = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (args: any) => {
      const [siteId, site] = args
      return await api.siteUpdate(siteId, site)
    },
    {
      ...options,
      onSuccess: (result, variables, context) => {
        queryClient.invalidateQueries('sites')
        if (options.onSuccess) {
          options.onSuccess(result, variables, context)
        }
      },
    }
  )
}

export const useDeleteSite = (options: UseMutationOptions = {}) => {
  const queryClient = useQueryClient()

  return useMutation(async (siteId) => await api.siteDestroy(siteId), {
    ...options,
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries('sites')
      if (options.onSuccess) {
        options.onSuccess(result, variables, context)
      }
    },
  })
}

export const useGetSiteStripeDashboardLink = (
  siteId: string,
  options?: UseQueryOptions
): UseQueryResult<any> => {
  return useQuery(
    ['siteDashboard', siteId, options],
    async () => await api.siteStripeDashboard(siteId),
    options
  )
}

export const useGetPmsIntegrations = (options: UseQueryOptions = {}): UseQueryResult<any> => {
  const siteId = useCurrentSiteId()

  options.cacheTime = options.cacheTime ?? Infinity
  options.staleTime = options.staleTime ?? 60 * 60 * 1000 // 60 minutes
  options.refetchOnMount = options.refetchOnMount ?? false

  return useQuery(
    ['pmsIntegrations', siteId],
    () => sitesApi.listPmsIntegrations({ siteId }),
    options
  )
}

export const useGetSoldByUsers = (options: UseQueryOptions = {}): UseQueryResult<any> => {
  const siteId = useCurrentSiteId()
  options.cacheTime = options.cacheTime ?? Infinity
  options.staleTime = options.staleTime ?? 60 * 60 * 1000 // 60 minutes

  return useQuery(
    ['soldByUsers', siteId],
    async () => await shopApi.siteSoldByUsersIndex(siteId),
    options
  )
}

export const useFlagsForSite = (siteId: string, options?: UseQueryOptions) =>
  useQuery(['siteFeatureFlags', { siteId }], () => api.siteFlags(siteId), options)

export const useGetSiteCategories = (options: UseQueryOptions = {}) => {
  const siteId = useCurrentSiteId()

  return useQuery(['siteCategories', siteId], () => sitesApi.getSiteCategories({ siteId }), options)
}
export const useGetSiteVoucherCategories = (options: UseQueryOptions = {}) => {
  const siteId = useCurrentSiteId()

  const voucherApplicable = true

  return useQuery(
    ['siteVoucherCategories', siteId],
    () => sitesApi.getSiteCategories({ siteId, voucherApplicable }),
    options
  )
}

export const useGetSiteSupportedCommsLocales = (siteId: string) =>
  useQuery(['supportedCommsLocales', { siteId }], () =>
    sitesApi.getSupportedCommsLocales({ siteId })
  )

export const useUpdateSiteSupportedCommsLocales = (siteId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    (locales: string[]) =>
      sitesApi.updateSupportedCommsLocales({
        siteId,
        updateSupportedCommsLocales: {
          locales,
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['supportedCommsLocales', { siteId }])
      },
    }
  )
}

export const useCheckSiteEmailFromAddressVerificationStatus = (): UseMutationResult => {
  const siteId = useCurrentSiteId()

  return useMutation(() =>
    sitesApi.actionCheckEmailFromAddressVerification({
      siteId,
    })
  )
}
