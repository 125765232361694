import { cloneElement, ReactElement } from 'react'
import { Dropdown } from './Dropdown'
import { Link } from 'react-router-dom'
import { ChevronRightIcon } from '@heroicons/react/solid'
import { classNames } from 'ui/utilities'
import { ConditionalWrapper } from './ConditionalWrapper'

interface Avatar {
  url?: string
  initials: string
}

interface Image {
  url?: string
  alt?: string
}

export interface ResourceListItemProps {
  toggle?: React.ReactNode
  title?: string | React.ReactNode
  titleLink?: string
  subtitle?: string | React.ReactNode
  truncateSubtitle?: boolean
  truncateTitle?: boolean
  badge?: React.ReactNode
  dropdownItems?: React.ReactNode[]
  avatar?: Avatar
  offeringImage?: Image
  compact?: boolean
  onClick?: () => void
  showDisclosureArrow?: boolean
  disabled?: boolean
  dropdownAboveModal?: boolean
  className?: string
}

export const ResourceListItem = ({
  toggle = null,
  title = '',
  titleLink = null,
  subtitle = null,
  truncateSubtitle = false,
  truncateTitle = false,
  badge = null,
  dropdownItems: inputDropdownItems = [],
  showDisclosureArrow = false,
  compact = false,
  disabled = false,
  avatar = null,
  offeringImage = null,
  dropdownAboveModal = false,
  onClick,
  className,
}: ResourceListItemProps) => {
  const dropdownItems = inputDropdownItems.map((item: ReactElement, i: number) =>
    cloneElement(item, {
      key: `dropdown_item_${i}`,
    })
  )

  return (
    <li
      className={classNames(
        'px-4 w-full flex text-sm',
        compact ? 'py-3' : 'py-4',
        showDisclosureArrow && !disabled && 'hover:bg-gray-50 cursor-pointer group',
        disabled && '!cursor-not-allowed opacity-50',
        className
      )}
    >
      <ConditionalWrapper
        condition={!disabled && onClick}
        wrapper={(children) => (
          <button className="w-full text-left flex" onClick={onClick} type="button">
            {children}
          </button>
        )}
      >
        <div className="flex-1 overflow-hidden">
          <div className="flex items-center space-x-2">
            <div className={'flex flex-1 items-center space-x-2'}>
              {avatar && (
                <div className="mr-2">
                  {avatar?.url ? (
                    <img className="h-10 w-10 rounded-full" src={avatar.url} alt="" />
                  ) : (
                    <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center">
                      <span className="font-medium text-sm text-gray-700">{avatar.initials}</span>
                    </div>
                  )}
                </div>
              )}

              {offeringImage && (
                <div className="mr-2 w-[100px] flex-shrink-0">
                  <div className="bg-gray-300 dark:bg-gray-800 rounded-md overflow-hidden aspect-offering flex items-center relative">
                    {offeringImage.url && (
                      <img
                        src={offeringImage.url}
                        alt={offeringImage.alt}
                        className="w-full h-full aspect-offering object-center object-cover"
                      />
                    )}
                  </div>
                </div>
              )}

              <div className="flex-1">
                <div className={`flex items-center space-x-1 ${truncateTitle && 'truncate'}`}>
                  {toggle}
                  {title && (
                    <div className="font-medium text-gray-800 dark:text-gray-200">
                      {titleLink && !disabled && (
                        <Link to={titleLink} title={title.toString()} rel="noreferrer">
                          {title}
                        </Link>
                      )}
                      {(!titleLink || (titleLink && disabled)) && title}
                    </div>
                  )}
                  {badge}
                </div>

                {subtitle && (
                  <div
                    className={`text-gray-500 ${compact ? 'mt-1' : 'mt-2'} ${
                      truncateSubtitle ? 'truncate' : ''
                    }`}
                  >
                    {subtitle}
                  </div>
                )}
              </div>
            </div>
            {showDisclosureArrow && dropdownItems.length === 0 && (
              <div className="flex-shrink-0 self-center pointer-events-none">
                <ChevronRightIcon
                  className="h-6 w-6 group-hover:text-gray-600 text-gray-700"
                  aria-hidden={true}
                />
              </div>
            )}
          </div>
        </div>
      </ConditionalWrapper>
      {dropdownItems.length > 0 && (
        <div className="flex-shrink-0">
          <Dropdown threeDots aboveModal={dropdownAboveModal}>
            {dropdownItems}
          </Dropdown>
        </div>
      )}
    </li>
  )
}
