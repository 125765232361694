import { useCurrentUserSitePermissions } from 'api/UserSiteRoles'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import { chain } from 'lodash'
import { useCallback } from 'react'

export const useCurrentUserCan = () => {
  const siteId = useCurrentSiteId()
  const { data: { data: currentUserRoles } = {} } = useCurrentUserSitePermissions()

  const permissions = chain(currentUserRoles)
    .filter(({ site_id: currentSiteId }) => siteId === currentSiteId || currentSiteId === null)
    .map('permissions')
    .flatten()
    .uniq()
    .value()

  return useCallback(
    (permission) => {
      return permissions.indexOf(permission) > -1
    },
    [permissions]
  )
}

export const useCurrentUserCanForCurrentSite = () => {
  const siteId = useCurrentSiteId()
  const { data: { data: currentUserRoles } = {} } = useCurrentUserSitePermissions()

  const permissions = chain(currentUserRoles)
    .filter(({ site_id: currentSiteId }) => siteId === currentSiteId)
    .map('permissions')
    .flatten()
    .uniq()
    .value()

  return useCallback(
    (permission) => {
      return permissions.indexOf(permission) > -1
    },
    [permissions]
  )
}

export const withUserHasPermission =
  (Component) =>
  ({ requiredPermission = '', ...props }) => {
    return (
      <IfUserHasPermission requiredPermission={requiredPermission}>
        <Component {...props} />
      </IfUserHasPermission>
    )
  }

export const IfUserHasPermission = ({ requiredPermission, children }) => {
  const can = useCurrentUserCan()

  if (requiredPermission && !can(requiredPermission)) return null

  return children
}
