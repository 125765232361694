import {
  XCircleIcon,
  InformationCircleIcon,
  ExclamationIcon,
  CheckCircleIcon,
} from '@heroicons/react/solid'
import classNames from 'classnames'
import { isEmpty } from 'lodash'
import { ReactNode } from 'react'

interface AlertProps {
  variant?: 'success' | 'warning' | 'danger' | 'info'
  title?: string
  listMessages?: string[]
  defaultMessage?: string
  border?: boolean
  children?: ReactNode
}

export const Alert: React.FC<AlertProps> = ({
  variant = 'danger',
  title = undefined,
  defaultMessage = 'Something went wrong!',
  listMessages = [],
  border = false,
  children,
}: AlertProps) => {
  const Icon: any = (props) => {
    switch (variant) {
      case 'success':
        return <CheckCircleIcon {...props} />
      case 'warning':
        return <ExclamationIcon {...props} />
      case 'danger':
        return <XCircleIcon {...props} />
      case 'info':
        return <InformationCircleIcon {...props} />
    }
  }

  const wrapperClasses = classNames(
    'rounded-xl overflow-hidden p-4',
    {
      'bg-green-50': variant === 'success',
      'bg-yellow-50': variant === 'warning',
      'bg-blue-50': variant === 'info',
      'bg-red-50': variant === 'danger',
    },
    {
      'border border-green-400': border && variant === 'success',
      'border border-yellow-400': border && variant === 'warning',
      'border border-blue-400': border && variant === 'info',
      'border border-red-400': border && variant === 'danger',
    }
  )

  const iconClasses = `h-5 w-5 ${
    variant === 'success'
      ? 'text-green-400'
      : variant === 'warning'
      ? 'text-yellow-400'
      : variant === 'info'
      ? 'text-blue-400'
      : variant === 'danger'
      ? 'text-red-400'
      : ''
  }`

  const displayTitle = title ?? defaultMessage

  const textClasses = classNames('text-sm', {
    'mt-2': !!displayTitle,
    'text-green-700': variant === 'success',
    'text-yellow-700': variant === 'warning',
    'text-blue-700': variant === 'info',
    'text-red-700': variant === 'danger',
  })

  return (
    <div className={wrapperClasses}>
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon className={iconClasses} aria-hidden="true" />
        </div>
        <div className="ml-3">
          {displayTitle && (
            <h3
              className={
                `text-sm font-medium ` +
                (variant === 'success' ? 'text-green-800' : '') +
                (variant === 'warning' ? 'text-yellow-800' : '') +
                (variant === 'info' ? 'text-blue-800' : '') +
                (variant === 'danger' ? 'text-red-800' : '')
              }
            >
              {displayTitle}
            </h3>
          )}
          {(children || listMessages.length > 0) && (
            <div className={textClasses}>
              {children}
              {listMessages.length > 0 && (
                <ul className="list-disc pl-5 space-y-1">
                  {listMessages.map((message, i) => (
                    <li key={i}>{message}</li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export const apiErrorToAlert: React.FC = (error: any) => {
  if (isEmpty(error)) {
    return null
  }

  const { message: title, errors = {} } = error
  const messages: string[] = Object.values(errors)

  return <Alert title={title} listMessages={messages} />
}
