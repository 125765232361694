export const CUSTOMERS_VIEW = 'clients.view'
export const CUSTOMERS_MANAGE = 'clients.manage'

export const INVENTORY_VIEW = 'inventory.view'
export const INVENTORY_MANAGE = 'inventory.manage'

export const REPORTS_VIEW = 'reports.view'
export const REPORTS_DOWNLOAD = 'reports.download'
export const REPORTS_PII_DOWNLOAD = 'reports.pii-download'

export const ORG_REPORTS_VIEW = 'org-reports.view'
export const ORG_REPORTS_DOWNLOAD = 'org-reports.download'

export const RESERVATIONS_VIEW = 'reservations.view'
export const RESERVATIONS_MANAGE = 'reservations.manage'

export const SETTINGS_VIEW = 'settings.view'
export const SETTINGS_MANAGE = 'settings.manage'

export const SHARED_SETTINGS_VIEW = 'shared-settings.view'
export const SHARED_SETTINGS_MANAGE = 'shared-settings.manage'

export const USERS_VIEW = 'users.view'
export const USERS_MANAGE = 'users.manage'

export const PAYMENTS_REFUND = 'payments.refund'

export const ROTAS_VIEW = 'rotas.view'
export const ROTAS_MANAGE = 'rotas.manage'

export const INTAKE_FORMS_VIEW = 'intake-forms.view'

export const ORG_USERS_MANAGE = 'org-users.manage'
