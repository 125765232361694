import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@trybeapp/ui.box'
import { Text } from '@trybeapp/ui.text'
import { FontAwesomeIcon } from 'components/FontAwesomeIcon'
import { STATUS_CANCELLED } from 'constants/orderStatuses'
import { Col, Row } from '@trybeapp/ui.grid'
import { useCanEditItem } from '../hooks'
import {
  CostColumn,
  DateColumn,
  StartTimeColumn,
  StatusColumn,
  PackageItemTypeColumn,
} from '../PackageColumns'
import { useCurrentOrder } from 'contexts/Order'
import {
  TYPE_APPOINTMENT,
  TYPE_SESSION,
  TYPE_PRODUCT,
  TYPE_AREA_BOOKING,
  TYPE_TABLE_RESERVATION,
} from 'constants/offeringTypes'
import { Tag } from '@trybeapp/ui.tag'
import { StatusTag } from '../Overview'
import { Card } from '../../../components/Card'
import { HeadingsRow } from './HeadingsRow'
import { selectPackageType } from 'selectors/PackageTypesSelectors'
import { useDeleteOrderItem } from 'api/OrderItems'
import { sentenceJoin } from 'utilities/StringUtils'
import { map, upperFirst } from 'lodash'
import { ItemActions } from './ItemActions'
import { DropdownItem } from 'ui/components/Dropdown'
import { PencilIcon } from '@heroicons/react/outline'
import { useOrderDetails } from 'screens/OrderDetails/contexts'
import { useActiveFeatureFlags } from 'contexts/SiteConfig'

export const PackageOrderItemRow = ({ item }) => {
  const { package_items: packageItems = [], type_name: name = '', status, type_id: typeId } = item
  const { product_code: code } = useSelector(selectPackageType(typeId)) ?? {}

  return (
    <Card className="my-2">
      <Card.Body>
        <Row>
          <Col col={0.7} mb="xs">
            <Box display="flex" alignItems="center">
              {code && (
                <Text as="span" variant="body3" color="nude.700" mr="xs">
                  <strong>[{code}]</strong>
                </Text>
              )}
              <Text as="span" variant="body3" color="nude.700">
                <strong>{name}</strong>
              </Text>
            </Box>
          </Col>
          <Col col={0.2} mb="xs">
            <Text
              variant="meta2"
              color="nude.400"
              fontWeight="bold"
              textTransform="uppercase"
              letterSpacing="sm"
            >
              Total
            </Text>
          </Col>
          <Col col={0.1} mb="xs"></Col>
        </Row>

        <Row>
          <Col col={0.7} mb="lg"></Col>
          <CostColumn item={item} />
          <PackageActionColumn item={item} />
        </Row>

        {status !== STATUS_CANCELLED && (
          <>
            <HeadingsRow slim={true} mt="xl" />
            {packageItems.map((item) => (
              <PackageItemRow packageItem={item} key={item.id} />
            ))}
          </>
        )}
        {status === STATUS_CANCELLED && <StatusTag status={STATUS_CANCELLED} />}
      </Card.Body>
    </Card>
  )
}

const PackageItemRow = ({ packageItem }) => {
  const { offering_type: type } = packageItem

  switch (type) {
    case TYPE_SESSION:
    case TYPE_APPOINTMENT:
    case TYPE_AREA_BOOKING:
    case TYPE_TABLE_RESERVATION:
      return <BookingItemRow item={packageItem} />
    case TYPE_PRODUCT:
      return <ProductRow item={packageItem} />
    default:
      return <Box>UNKNOWN</Box>
  }
}

const BookingItemRow = ({ item }) => {
  return (
    <Row alignItems="center" py="xs">
      <StatusColumn item={item} />
      <DateColumn item={item} />
      <StartTimeColumn item={item} />
      <PackageItemTypeColumn item={item}>
        <BookingResourceSummary item={item} />
        <BookingItemErrors item={item} />
      </PackageItemTypeColumn>
      <Col col={0.1}></Col>
    </Row>
  )
}

const ProductRow = ({ item }) => {
  const { offering_name: offeringName } = item

  return (
    <Row alignItems="center" py="xs">
      <Col col={0.13 + 0.15 + 0.12}></Col>
      <Col col={0.35}>
        <span className="text-sm">{offeringName}</span>
      </Col>
      <Col col={0.1}></Col>
    </Row>
  )
}

const PackageActionColumn = ({ item }) => {
  const { order: { id: orderId } = {} } = useCurrentOrder()
  const { id: itemId = '', status: itemStatus } = item
  const featureFlags = useActiveFeatureFlags()
  const canEdit = useCanEditItem(item)

  const { mutate, isLoading: isDeleting } = useDeleteOrderItem()
  const handleDelete = () => {
    mutate([orderId, itemId])
  }

  return (
    <Col col={0.1}>
      <ItemActions item={item} handleDelete={handleDelete} isDeleting={isDeleting}>
        {canEdit && <EditItemButtonNew item={item} />}
      </ItemActions>
    </Col>
  )
}

export const BookingResourceSummary = ({ item }) => {
  const { offering_type: itemType = '', booking_summary: bookingSummary = {} } = item

  const resourceString = useMemo(() => {
    const practitioners = bookingSummary.practitioners || []
    const areas = bookingSummary.areas || []
    const room = bookingSummary.room || {}

    const strings = []

    if (practitioners.length > 0) {
      strings.push(`with ${sentenceJoin(map(practitioners, 'name'), ',', '&')}`)
    }

    if (areas.length > 0) {
      strings.push(`in ${sentenceJoin(map(areas, 'name'), ',', '&')}`)
    }

    if (room?.name) {
      strings.push(`in ${room.name}`)
    }

    return upperFirst(strings.join(' ') ?? '')
  }, [bookingSummary.practitioners, bookingSummary.areas, bookingSummary.room])

  if ([TYPE_APPOINTMENT, TYPE_AREA_BOOKING].indexOf(itemType) < 0 || !resourceString) return null

  return (
    <Text variant="meta1" color="nude.500" lineHeight={1.2}>
      {resourceString}
    </Text>
  )
}

const useBookingItemErrors = (item) => {
  const { booking_summary: bookingSummary = {}, guests = [], offering_type: itemType = '' } = item
  const numGuests = guests.length || 1
  const { practitioners, room, areas } = bookingSummary
  const [errors, setErrors] = useState([])

  useEffect(() => {
    const errorsSet = new Set(errors)

    if (
      itemType === TYPE_APPOINTMENT &&
      !errorsSet.has('Practitioner') &&
      (!practitioners || practitioners.length < numGuests)
    ) {
      errorsSet.add('Practitioner')
    }

    if (practitioners && practitioners.length >= numGuests && errorsSet.has('Practitioner')) {
      errorsSet.delete('Practitioner')
    }

    if (
      itemType === TYPE_AREA_BOOKING &&
      !errorsSet.has('Area') &&
      (!areas || areas.length < numGuests)
    ) {
      errorsSet.add('Area')
    }

    if (areas && areas.length > 0 && errorsSet.has('Area')) {
      errorsSet.delete('Area')
    }

    if (itemType === TYPE_APPOINTMENT && !errorsSet.has('Room') && !room) {
      errorsSet.add('Room')
    }

    if (room && errorsSet.has('Room')) {
      errorsSet.delete('Room')
    }

    setErrors(Array.from(errorsSet))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practitioners, room])

  return errors
}

const BookingItemErrors = ({ item }) => {
  const errors = useBookingItemErrors(item)

  if (errors.length === 0) return null

  return (
    <Box mt="0.2rem">
      <Tag variant="error">
        <FontAwesomeIcon icon="exclamation-circle" />
        {errors.join(', ')}
      </Tag>
    </Box>
  )
}

const EditItemButtonNew = ({ item }) => {
  const { setValue } = useOrderDetails()

  const handleClick = () => {
    setValue('packageWizard.editingPackage', item)
  }

  return <DropdownItem label="Edit" onClick={handleClick} icon={PencilIcon} />
}
