import { useMemo, useState, useEffect } from 'react'
import { Button } from 'ui/components/Button'
import { FontAwesomeIcon } from 'components/FontAwesomeIcon'
import {
  STATUS_CANCELLED,
  STATUS_NO_SHOW,
  STATUS_SETTLED,
  STATUS_SUBMITTED,
} from 'constants/orderStatuses'
import {
  TYPE_APPOINTMENT,
  TYPE_APPOINTMENT_ENQUIRY,
  TYPE_AREA_BOOKING,
  TYPE_COURSE,
  TYPE_HOTEL_ROOM_RESERVATION,
  TYPE_MEMBERSHIP,
  TYPE_PACKAGE,
  TYPE_PRODUCT,
  TYPE_SESSION,
  TYPE_TABLE_RESERVATION,
  TYPE_VOUCHER,
} from 'constants/offeringTypes'
import { LinkWithPreload } from 'utilities/PreloadLinks'
import { GuestsProvider, useGuests } from 'contexts/Guest'
import pluralize from 'pluralize'
import { Modal } from '@trybeapp/ui.modal'
import { useAllActiveOrderItemDates, useAllActiveOrderItems, useAllOrderItems } from './hooks'
import { useCurrentOrder } from 'contexts/Order'
import { AppointmentItemRow } from './components/AppointmentItemRow'
import { SessionItemRow } from './components/SessionItemRow'
import { PackageOrderItemRow } from './components/PackageItemRow'
import { useAddProductOrderItemOverlay } from 'components/AddProductOrderItemOverlay/contexts'
import { ProductItemRow } from './components/ProductItemRow'
import { MembershipItemRow } from './components/MembershipItemRow'
import { useGetOrder, useLockOrder, useUnlockOrder } from 'api/Orders'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { map } from 'lodash'
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons'
import { Toast, useToast } from 'ui/components/Notification'
import { useErrorToast } from 'components/ErrorToast/ErrorToast'
import { useAddAreaBookingOrderItemOverlay } from 'components/AddAreaBookingOrderItemOverlay/contexts'
import { AreaBookingItemRow } from './components/AreaBookingItemRow'
import { useAddCourseOrderItemOverlay } from 'components/AddCourseOrderItemOverlay/contexts'
import { Dropdown, DropdownItem } from 'ui/components/Dropdown'
import { PlusCircleIcon } from '@heroicons/react/solid'
import { VoucherItemRow } from './components/VoucherItemRow'
import AddVoucherOrderItemWizard from 'components/AddVoucherOrderItemWizard'
import { useActiveFeatureFlags } from 'contexts/SiteConfig'
import { useGetOrderItineraryDoc } from 'api/Orders'
import { Notification, useNotificationContext } from 'ui/components/Notification'
import { useOrderDetails } from 'screens/OrderDetails/contexts'
import { CourseItemRow } from './components/CourseItemRow'
import { ReceiptPrintButton } from './ReceiptPrintButton'
import { formatGuestNames } from 'utilities/StringUtils/stringUtils'
import { AppointmentEnquiryItemRow } from './components/AppointmentEnquiryItemRow'
import { toIsoDate } from 'utilities/DateUtils/dateUtils'
import { TableReservationItemRow } from './components/TableReservationItemRow'
import { HotelRoomReservationItemRow } from './components/HotelRoomReservationItemRow'

export const OrderItemsSection = ({ orderDetailsBeta }) => {
  const { order: { id: orderId } = {} } = useCurrentOrder()
  const { state, pathname } = useLocation()
  const history = useHistory()
  const { setValue } = useOrderDetails()

  useEffect(() => {
    if (state?.showAddModal === TYPE_PRODUCT) {
      setValue('productWizard.editingProduct', {})

      // Remove the value from state so the wizard doesn't pop up again
      const newState = { ...state }
      delete newState.showAddModal
      history.replace(pathname, newState)
    }
  }, [state])

  return (
    <>
      {!orderDetailsBeta && (
        <div className="my-3">
          <div className="flex items-center">
            <div className="flex-1 text-violet-700 font-semibold">Items</div>
            <div className="flex items-center gap-1">
              <ReceiptPrintButton orderId={orderId} />
              <LinkToCalendar />
              <DownloadItineraryButton />
              <div className="flex-1 text-right">
                <LockingButton orderId={orderId} />
              </div>
            </div>
          </div>
        </div>
      )}
      <OrderItemsList />
    </>
  )
}

const OrderItemsList = () => {
  const { order: { guests = [], status } = {} } = useCurrentOrder()
  const { getValue } = useOrderDetails()
  const orderDetailsBeta = getValue('orderDetailsBeta')
  const hasGuests = guests.length > 0
  const { orderId } = useParams()
  const { status: loadingStatus } = useGetOrder(orderId)
  const featureFlags = useActiveFeatureFlags()

  return loadingStatus === 'loading' ? (
    <div className="flex flex-col gap-y-2 animate-pulse">
      <div className="h-24 bg-gray-100" />
      <div className="h-24 bg-gray-100" />
      <div className="h-24 bg-gray-100" />
    </div>
  ) : (
    <>
      <div>
        <div className={orderDetailsBeta ? 'grid grid-cols-2 md:flex gap-1' : 'flex space-x-1'}>
          {![STATUS_SETTLED, STATUS_NO_SHOW, STATUS_CANCELLED].includes(status) && (
            <>
              <AddAppointmentButton />
              <AddSessionButton />
              {!featureFlags.includes('new_add_area_booking') && <AddAreaBookingButton />}
              {featureFlags.includes('new_add_area_booking') && <AddAreaBookingV2Button />}
              <AddPackageV2Button />
              <AddOtherDropdown />
            </>
          )}
        </div>
      </div>
      <>{hasGuests ? <ItemsByGuest /> : <Items />}</>
    </>
  )
}

export const LockingButton = ({ orderId }) => {
  const { order: { status, is_locked: locked } = {} } = useCurrentOrder()
  const toast = useToast()
  const { mutate: lock, isLoading: locking } = useLockOrder()
  const { mutate: unlock, isLoading: unlocking } = useUnlockOrder()
  const errorToast = useErrorToast()

  const loading = locking || unlocking

  const handleClick = () => {
    if (locked) {
      unlock(orderId, {
        onError: (error) => errorToast(error),
        onSuccess: () => {
          toast(
            <Toast variant="success">
              <Toast.Title>Success</Toast.Title>
              Order unlocked successfully
            </Toast>,
            { position: 'top-right' }
          )
        },
      })
    } else {
      lock(orderId, {
        onError: (error) => errorToast(error),
        onSuccess: () => {
          toast(
            <Toast variant="success">
              <Toast.Title>Success</Toast.Title>
              Order locked successfully
            </Toast>,
            { position: 'top-right' }
          )
        },
      })
    }
  }

  if (![STATUS_SETTLED, STATUS_SUBMITTED].includes(status)) return null

  return (
    <Button variant="ghost" size="sm" onClick={handleClick} loading={loading}>
      <FontAwesomeIcon
        icon={locked ? faUnlock : faLock}
        mr="sm"
        color={locked ? 'success.500' : 'danger.500'}
      />
      {locked ? 'Unlock' : 'Lock'}
    </Button>
  )
}

const RowComponentForSchema = ({ type, item }) => {
  const featureFlags = useActiveFeatureFlags()

  switch (type) {
    case TYPE_APPOINTMENT:
      return <AppointmentItemRow item={item} />

    case TYPE_APPOINTMENT_ENQUIRY:
      return <AppointmentEnquiryItemRow item={item} />

    case TYPE_SESSION:
      return <SessionItemRow item={item} />

    case TYPE_AREA_BOOKING:
      return <AreaBookingItemRow item={item} />

    case TYPE_PRODUCT:
      return <ProductItemRow item={item} />

    case TYPE_COURSE:
      return <CourseItemRow item={item} />

    case TYPE_PACKAGE:
      return <PackageOrderItemRow item={item} />

    case TYPE_TABLE_RESERVATION:
      return <TableReservationItemRow item={item} />

    case TYPE_MEMBERSHIP:
      return <MembershipItemRow item={item} />

    case TYPE_VOUCHER:
      return <VoucherItemRow item={item} />

    case TYPE_HOTEL_ROOM_RESERVATION:
      return featureFlags.includes('overnights') && <HotelRoomReservationItemRow item={item} />

    default:
      return null
  }
}

const Items = () => {
  const { order: { status } = {} } = useCurrentOrder()
  const activeOrderItems = useAllActiveOrderItems()
  const allOrderItems = useAllOrderItems()

  const orderItems = useMemo(
    () => ([STATUS_CANCELLED, STATUS_NO_SHOW].includes(status) ? allOrderItems : activeOrderItems),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [status]
  )

  return (
    <>
      {orderItems.map((item) => (
        <RowComponentForSchema type={item.item_type} key={item.id} item={item} />
      ))}
      {orderItems.length === 0 && (
        <div className="mt-4 text-gray-400 text-sm">This order has no items</div>
      )}
    </>
  )
}

export const useItemsByGuest = (orderItems = []) => {
  return useMemo(() => {
    return orderItems.reduce((byGuestIds, basketItem) => {
      const guestIds = map(basketItem.guests, 'id').sort().join(',')
      byGuestIds[guestIds] = byGuestIds[guestIds] || []
      byGuestIds[guestIds].push(basketItem)

      return byGuestIds
    }, {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(map(orderItems, 'updated_at'))])
}

const ItemsByGuest = () => {
  const orderItems = useAllActiveOrderItems()
  const itemsByGuests = useItemsByGuest(orderItems)

  return Object.keys(itemsByGuests).map((guestIds) => {
    const items = itemsByGuests[guestIds]

    return (
      <GuestsProvider ids={guestIds} key={guestIds}>
        <GuestItems items={items} />
      </GuestsProvider>
    )
  })
}

const GuestItems = ({ items }) => {
  const guests = useGuests()

  return (
    <>
      <GuestNameRow guests={guests} numItems={items.length} />
      {items.map((item) => (
        <RowComponentForSchema type={item.item_type} key={item.id} item={item} />
      ))}
    </>
  )
}

const GuestNameRow = ({ guests, numItems }) => {
  const itemWord = pluralize('item', numItems)
  const name = formatGuestNames(guests)

  return (
    <div className="w-full mt-4 text-gray-600 text-sm">
      {numItems} {itemWord} for <strong>{name}</strong>
    </div>
  )
}

const LinkToCalendar = () => {
  const { orderId } = useParams()
  const dates = useAllActiveOrderItemDates()

  if (dates.length === 0) return null

  return (
    <LinkWithPreload
      to={`/calendar?date=${encodeURIComponent(toIsoDate(dates[0]))}&orderId=${orderId}`}
      ml="lg"
    >
      <Button variant="ghost" size="xs">
        <FontAwesomeIcon icon={['far', 'calendar-alt']} mr="sm" />
        View on calendar
      </Button>
    </LinkWithPreload>
  )
}

const DownloadItineraryButton = () => {
  const { orderId } = useParams()
  const [downloadEnabled, setDownloadEnabled] = useState(false)
  const notifications = useNotificationContext()

  const { data: { data: { download_url: downloadUrl } = {}, isLoading } = {} } =
    useGetOrderItineraryDoc(orderId, {
      enabled: downloadEnabled,
      onSuccess: () => {
        notifications.notify(
          <Notification
            title="Download started"
            description="The itinerary has been created and is now downloading"
            variant="success"
            autoDismiss
          />
        )
      },
    })

  const handleClick = () => {
    setDownloadEnabled(true)
  }

  useEffect(() => {
    if (downloadEnabled && downloadUrl) {
      window.location = downloadUrl
      setDownloadEnabled(false)
    }
  }, [downloadEnabled, downloadUrl])

  return (
    <Button variant="ghost" size="xs" onClick={handleClick} loading={isLoading}>
      <FontAwesomeIcon icon="file-alt" mr="sm" />
      Itinerary
    </Button>
  )
}

const AddAppointmentButton = () => {
  const { setValue, getValue } = useOrderDetails()
  const orderDetailsBeta = getValue('orderDetailsBeta')

  const openWizard = () => {
    setValue('appointmentWizard.editingAppointment', {})
  }

  return (
    <>
      <Button variant={orderDetailsBeta ? 'default' : 'secondary'} size="xs" onClick={openWizard}>
        <FontAwesomeIcon icon="plus-circle" mr="sm" /> Add appointment
      </Button>
    </>
  )
}

const AddSessionButton = () => {
  const { setValue, getValue } = useOrderDetails()
  const orderDetailsBeta = getValue('orderDetailsBeta')

  const openWizard = () => {
    setValue('sessionWizard.editingSession', {})
  }

  return (
    <>
      <Button variant={orderDetailsBeta ? 'default' : 'secondary'} size="xs" onClick={openWizard}>
        <FontAwesomeIcon icon="plus-circle" mr="sm" /> Add session
      </Button>
    </>
  )
}

const AddAreaBookingButton = () => {
  const { getValue } = useOrderDetails()
  const orderDetailsBeta = getValue('orderDetailsBeta')
  const { modal } = useAddAreaBookingOrderItemOverlay()

  return (
    <Modal.Trigger
      as={Button}
      variant={orderDetailsBeta ? 'default' : 'secondary'}
      size="xs"
      {...modal}
    >
      <FontAwesomeIcon icon="plus-circle" mr="sm" /> Add area booking
    </Modal.Trigger>
  )
}

const AddAreaBookingV2Button = () => {
  const { getValue, setValue } = useOrderDetails()
  const orderDetailsBeta = getValue('orderDetailsBeta')

  const openWizard = () => {
    setValue('areaBookingWizard.editingAreaBooking', {})
  }

  return (
    <>
      <Button variant={orderDetailsBeta ? 'default' : 'secondary'} size="xs" onClick={openWizard}>
        <FontAwesomeIcon icon="plus-circle" mr="sm" /> Add area booking
      </Button>
    </>
  )
}

const AddPackageV2Button = () => {
  const { getValue, setValue } = useOrderDetails()
  const orderDetailsBeta = getValue('orderDetailsBeta')

  const openWizard = () => {
    setValue('packageWizard.editingPackage', {})
  }

  return (
    <>
      <Button variant={orderDetailsBeta ? 'default' : 'secondary'} size="xs" onClick={openWizard}>
        <FontAwesomeIcon icon="plus-circle" mr="sm" /> Add package
      </Button>
    </>
  )
}

const AddTableReservationDropdownItem = () => {
  const { setValue } = useOrderDetails()

  const openWizard = () => {
    setValue('tableReservationWizard.editingTableReservation', {})
  }

  return <DropdownItem label="Table reservation" onClick={openWizard} icon={PlusCircleIcon} />
}

const AddCourseDropdownItem = () => {
  const { modal } = useAddCourseOrderItemOverlay()

  return <DropdownItem label="Course" onClick={() => modal.show()} icon={PlusCircleIcon} />
}

const AddCourseV2DropdownItem = () => {
  const { setValue } = useOrderDetails()

  const openWizard = () => {
    setValue('courseWizard.editingCourse', {})
  }

  return <DropdownItem label="Course" onClick={openWizard} icon={PlusCircleIcon} />
}

const AddProductDropdownItem = () => {
  const { modal } = useAddProductOrderItemOverlay()

  return <DropdownItem label="Product" onClick={() => modal.show()} icon={PlusCircleIcon} />
}

const AddProductV2DropdownItem = () => {
  const { setValue } = useOrderDetails()

  const openWizard = () => {
    setValue('productWizard.editingProduct', {})
  }

  return <DropdownItem label="Product" onClick={openWizard} icon={PlusCircleIcon} />
}

const AddVoucherDropdownItem = ({ setIsOpen }) => {
  return <DropdownItem label="Voucher" onClick={() => setIsOpen(true)} icon={PlusCircleIcon} />
}

const AddOtherDropdown = () => {
  const [isVoucherWizardOpen, setVoucherWizardOpen] = useState(false)
  const featureFlags = useActiveFeatureFlags()

  return (
    <>
      <Dropdown
        buttonLabel="Add other"
        buttonLeadingIcon={PlusCircleIcon}
        buttonVariant="ghost"
        buttonSize="xs"
      >
        {!featureFlags.includes('add_course_wizard') && <AddCourseDropdownItem />}
        {featureFlags.includes('add_course_wizard') && <AddCourseV2DropdownItem />}
        {!featureFlags.includes('add_product_wizard') && <AddProductDropdownItem />}
        {featureFlags.includes('add_product_wizard') && <AddProductV2DropdownItem />}
        <AddVoucherDropdownItem setIsOpen={setVoucherWizardOpen} />
        {featureFlags.includes('has_table_reservation_integration') && (
          <AddTableReservationDropdownItem />
        )}
      </Dropdown>
      <AddVoucherOrderItemWizard
        isOpen={isVoucherWizardOpen}
        onClose={() => setVoucherWizardOpen(false)}
      />
    </>
  )
}
