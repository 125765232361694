import { useInfiniteQuery, useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import { HotelRoomTypesApi } from '@trybeapp/sdk'
import { apiConfiguration } from 'utilities/TrybeApiClient'

const api = new HotelRoomTypesApi(apiConfiguration)

export const useHotelRoomTypes = (
  params: any = {},
  options?: UseQueryOptions
): UseQueryResult<any> => {
  const siteId = useCurrentSiteId()
  params.siteId = params.siteId ?? siteId

  return useQuery(['hotelRoomTypes', params], () => api.listHotelRoomTypes(params), options)
}

export const useInfiniteHotelRoomTypes = (params: any = {}) => {
  const siteId = useCurrentSiteId()
  params.siteId = params.siteId ?? siteId

  return useInfiniteQuery(['inifiniteRoomReservationTypes', { params }], fetchHotelRoomTypes, {
    getNextPageParam: (lastPage: any) => {
      return lastPage.nextPage
    },
  })
}

const fetchHotelRoomTypes = async ({ queryKey, pageParam: page = 1 }) => {
  const { params } = queryKey[1]
  const response = await api.listHotelRoomTypes({ page, ...params })

  return {
    data: response.data,
    page,
    nextPage: response.meta.last_page > page ? page + 1 : undefined,
    total: response.meta.total,
  }
}

export const useGetHotelRoomType = (hotelRoomTypeId: string, options = {}) =>
  useQuery(
    ['hotelRoomType', { hotelRoomTypeId }],
    () => api.getHotelRoomType({ hotelRoomTypeId }),
    options
  )
