import { ChangeEventHandler, ForwardedRef, forwardRef } from 'react'
import { Label } from './Label'

interface TextareaProps {
  /** The label for the textarea */
  label?: string
  /** An optional description for the textarea */
  description?: string[]
  /** The value for the textarea */
  value?: any
  /** Fired when the input is changed */
  onChange?: ChangeEventHandler<HTMLTextAreaElement>
  /** The name of the textarea element */
  name?: string
  /** Whether the label should be hidden */
  hideLabel?: boolean
  /** The placeholder for the textarea */
  placeholder?: string
  /** The character count for the textarea */
  shouldShowCharacterCount?: boolean
  /** The character count and message count for the textarea */
  shouldShowCharacterAndMessageCount?: boolean
  /** The character count offset */
  characterCountOffset?: number
  /** Whether the textarea is disabled for editing */
  disabled?: boolean
}

export const Textarea = forwardRef((props: TextareaProps, ref: ForwardedRef<any>) => {
  const offset = props.characterCountOffset > 0 ? props.characterCountOffset : 0

  const characterCount = (props.value ?? '').length + offset
  const messageCount = Math.ceil(characterCount / 130)

  return (
    <div>
      <Label htmlFor={props.name} hide={props.hideLabel || !props.label} text={props.label} />

      <div className={(!props.hideLabel && props.label ? 'mt-2' : '') + ' relative'}>
        <textarea
          ref={ref}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          placeholder={props.placeholder}
          disabled={props.disabled}
          className={`shadow-sm focus:ring-violet focus:ring-2 focus:ring-offset-2 focus:border-gray-300 block bg-background-input w-full sm:text-sm border-border rounded-lg min-h-[100px] ${
            props.disabled && 'opacity-50 cursor-not-allowed'
          }`}
        />
        {props.shouldShowCharacterCount && (
          <div className="absolute mt-2 right-2 text-violet-500 text-xs font-semibold">
            {characterCount} characters
          </div>
        )}
        {props.shouldShowCharacterAndMessageCount && (
          <div className="absolute mt-2 right-2 text-violet-500 text-xs font-semibold">
            {characterCount} characters • {messageCount}{' '}
            {messageCount === 1 ? 'message' : 'messages'}
          </div>
        )}
      </div>
    </div>
  )
})
