import {
  useInfiniteQuery,
  useQueryClient,
  useMutation,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'
import { PackagesApi } from '@trybeapp/sdk.shop'
import { PackagesApi as PackagesApiTs } from '@trybeapp/sdk'
import { DateTime } from 'luxon'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import { usePageQueryParam } from 'hooks/UsePageQueryParam'
import { apiConfiguration } from 'utilities/TrybeApiClient'
import { sdkDateToSystemDateTime } from 'utilities/DateUtils/dateUtils'

const api = new PackagesApi()
const tsApi = new PackagesApiTs(apiConfiguration)

export const usePackages = (params: any = {}, options?: UseQueryOptions): UseQueryResult<any> => {
  params.siteId = useCurrentSiteId()

  return useQuery(['packages', params], async () => api.packagesIndex(params), options)
}

export const useAllPackages = (options: UseQueryOptions = {}): UseQueryResult<any> => {
  options.cacheTime = options.cacheTime ?? Infinity
  options.staleTime = options.staleTime ?? Infinity

  return usePackages({ perPage: -1 }, options)
}

export const usePagedPackages = (params = {}, options?: UseQueryOptions): UseQueryResult<any> => {
  const [page] = usePageQueryParam()

  return usePackages({ page, ...params }, options)
}

export const useGetPackage = (packageId: string, options?: UseQueryOptions) =>
  useQuery<unknown, unknown, any>(
    ['packages', packageId],
    async () => api.packageShow(packageId),
    options
  )

export const useInfinitePackages = (params: any = {}) => {
  params.siteId = useCurrentSiteId()

  return useInfiniteQuery(['packages', { params }], fetchPackages, {
    getNextPageParam: (lastPage: any) => {
      return lastPage.nextPage
    },
  })
}

const fetchPackages = async ({ queryKey, pageParam: page = 1 }) => {
  const { params } = queryKey[1]

  const response = await api.packagesIndex({ page, ...params })

  return {
    data: response.data,
    page,
    nextPage: response.meta.last_page > page ? page + 1 : undefined,
    total: response.meta.total,
  }
}

export const useCreatePackage = () => {
  const queryClient = useQueryClient()

  return useMutation((packageType: any) => api.packageStore(packageType), {
    onSuccess: () => {
      queryClient.invalidateQueries(['packages'])
    },
  })
}

export const useCopyPackage = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (args: any) => {
      const [copyFromId, packageType] = args

      return await api.packageCopy(copyFromId, { _package: packageType })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['packages'])
      },
    }
  )
}

export const useUpdatePackage = () => {
  const queryClient = useQueryClient()

  return useMutation(([packageId, packageType]: any) => api.packageUpdate(packageId, packageType), {
    onSuccess: () => {
      queryClient.invalidateQueries('packages')
    },
  })
}

export const useDeletePackage = () => {
  const queryClient = useQueryClient()

  return useMutation(([packageId]: any) => api.packageDestroy(packageId), {
    onSuccess: () => {
      queryClient.invalidateQueries('packages')
    },
  })
}

export const useRestorePackage = () => {
  const queryClient = useQueryClient()

  return useMutation((packageId: any) => api.packageRestore(packageId), {
    onSuccess: () => {
      queryClient.invalidateQueries('packages')
    },
  })
}

export const useCreatePackageChoice = () => {
  const queryClient = useQueryClient()

  return useMutation(([packageId, choice]: any) => api.packageItemChoiceStore(packageId, choice), {
    onSuccess: () => {
      queryClient.invalidateQueries('packages')
    },
  })
}

export const useUpdatePackageChoice = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ([packageId, choiceId, choice]: any) =>
      api.packageItemChoiceUpdate(packageId, choiceId, choice),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('packages')
      },
    }
  )
}

export const useDeletePackageChoice = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ([packageId, choiceId]: any) => api.packageItemChoiceDestroy(packageId, choiceId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('packages')
      },
    }
  )
}

export const usePackageChoiceReorder = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ([packageId, params]: any) => api.packageItemChoiceReorder(packageId, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('packages')
      },
    }
  )
}

export const usePackageAvailabilityRules = (
  packageId: string,
  params: any = {},
  options?: UseQueryOptions
): UseQueryResult<any> =>
  useQuery(
    ['packageAvailabilityRules', { packageId, params }],
    async () => {
      const response = await api.packageAvailabilityRulesIndex(packageId, params)

      response.data.forEach((availabilityRule) => {
        availabilityRule.date_from = availabilityRule.date_from
          ? sdkDateToSystemDateTime(availabilityRule.date_from).toJSDate()
          : null

        availabilityRule.date_to = availabilityRule.date_to
          ? sdkDateToSystemDateTime(availabilityRule.date_to).toJSDate()
          : null
      })

      return response
    },
    options
  )

export const usePagedPackageAvailabilityRules = (
  packageId,
  params = {},
  options?: UseQueryOptions
): UseQueryResult<any> => {
  const [page] = usePageQueryParam()

  return usePackageAvailabilityRules(packageId, { page, params }, options)
}

export const useCreatePackageAvailabilityRule = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ([packageId, values]: any) =>
      api.packageAvailabilityRuleStore(packageId, {
        date_from: DateTime.fromJSDate(values.date_from).toISODate(),
        date_to: DateTime.fromJSDate(values.date_to).toISODate(),
        is_available: values.is_available,
        time_from: values.time_from,
        time_to: values.time_to,
        weekdays: values.weekdays,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['packageAvailabilityRules'])
      },
    }
  )
}

export const useUpdatePackageAvailabilityRule = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ([packageId, ruleId, values]: any) =>
      api.packageAvailabilityRuleUpdate(packageId, ruleId, {
        date_from: DateTime.fromJSDate(values.date_from).toISODate(),
        date_to: DateTime.fromJSDate(values.date_to).toISODate(),
        is_available: values.is_available,
        time_from: values.time_from,
        time_to: values.time_to,
        weekdays: values.weekdays,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['packageAvailabilityRules'])
      },
    }
  )
}

export const useDeletePackageAvailabilityRule = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ([packageId, ruleId]: any) => api.packageAvailabilityRuleDestroy(packageId, ruleId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['packageAvailabilityRules'])
      },
    }
  )
}

export const usePackagePriceRules = (packageId: string, params: any = {}) =>
  useQuery(['packagePriceRules', packageId, { params }], async () => {
    const response = await api.packagePriceRulesIndex(packageId, params)

    response.data.forEach((priceRule) => {
      priceRule.date_from = priceRule.date_from
        ? sdkDateToSystemDateTime(priceRule.date_from).toJSDate()
        : null

      priceRule.date_to = priceRule.date_to
        ? sdkDateToSystemDateTime(priceRule.date_to).toJSDate()
        : null
    })

    return response
  })

export const usePagedPackagePriceRules = (packageId: string, params = {}) => {
  const [page] = usePageQueryParam()

  return usePackagePriceRules(packageId, { page, params })
}

export const useCreatePackagePriceRule = () => {
  const queryClient = useQueryClient()

  return useMutation(([packageId, rule]: any) => api.packagePriceRuleStore(packageId, rule), {
    onSuccess: (resp, [packageId]) => {
      queryClient.invalidateQueries(['packagePriceRules', packageId])
    },
  })
}

export const useUpdatePackagePriceRule = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ([packageId, ruleId, rule]: any) => api.packagePriceRuleUpdate(packageId, ruleId, rule),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['packagePriceRules'])
      },
    }
  )
}

export const useDeletePackagePriceRule = () => {
  const queryClient = useQueryClient()

  return useMutation(([packageId, ruleId]: any) => api.packagePriceRuleDestroy(packageId, ruleId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['packagePriceRules'])
    },
  })
}

export const useSuggestDescriptionForPackage = (packageId: string) =>
  useMutation((description?: string) =>
    tsApi.actionSuggestDescriptionForPackage({
      packageId,
      suggestDescription2: { description },
    })
  )
