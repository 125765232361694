import classNames from 'classnames'

interface CardProps {
  children: React.ReactNode
  className?: string
  transparent?: boolean
}

export const Card = ({ children, className = '', transparent = false }: CardProps) => {
  const classes = classNames([
    className,
    {
      'bg-transparent': transparent,
      'bg-background': !transparent && !className.includes('bg-'),
    },
    {
      'border-border': !className.includes('border-'),
    },
    {
      'shadow-sm': !className.includes('shadow-'),
    },
    'rounded-md border',
  ])

  return <div className={classes}>{children}</div>
}

Card.Body = ({ children }) => <div className="p-3">{children}</div>
