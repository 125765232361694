import { Children, cloneElement, useMemo } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/outline'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { DragDropContext, Droppable, OnDragEndResponder } from 'react-beautiful-dnd'
import { Button } from './Button'
import pluralize from 'pluralize'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

const LoadingRow = () => (
  <div className="p-4 animate-pulse">
    <div className="w-32 h-5 bg-gray-100" />
    <div className="w-3/4 h-4 mt-3 bg-gray-100" />
  </div>
)

interface PaginatedMiniListProps {
  /** Whether the contents of the list is loading */
  isLoading?: boolean
  /** Whether there was an error fetching the contents of the list */
  isError?: boolean
  /** The contents of the error returned. This includes the status code */
  error?: unknown
  /** An array of components to render for each row */
  rows?: React.ReactNode[]
  /** An object containing pagination info */
  meta?: {
    total: number // The total number of results
    current_page: number // The current page
    last_page: number // The number of pages
  }
  /** Callback for when the page is changed */
  onPageChange?: (page: number) => void
  /** A message to display when the list is empty */
  emptyMessage?: string
  /** Whether to display a 'lighter weight' version of the list without borders */
  borderless?: boolean
  /** Whether to hide the pagination options if there's only one page of results */
  hidePaginationIfSinglePage?: boolean
  /** Whether the elements in the list should be draggable */
  draggable?: boolean
  /** A function to be called when dragging ends */
  onDragEnd?: OnDragEndResponder
  /** An ID for the droppable section */
  droppableId?: string
}

export const PaginatedMiniList: React.FC<PaginatedMiniListProps> = ({
  isLoading = false,
  isError = false,
  error = null,
  rows = [],
  meta = null,
  onPageChange = (_: number) => {},
  emptyMessage,
  borderless = false,
  hidePaginationIfSinglePage = false,
  draggable = false,
  droppableId = 'mini-list',
  onDragEnd,
}) => (
  <DragDropWrapper draggable={draggable} onDragEnd={onDragEnd} droppableId={droppableId}>
    <List
      isLoading={isLoading}
      isError={isError}
      error={error}
      rows={rows}
      meta={meta}
      onPageChange={onPageChange}
      emptyMessage={emptyMessage}
      borderless={borderless}
      hidePaginationIfSinglePage={hidePaginationIfSinglePage}
    />
  </DragDropWrapper>
)

const DragDropWrapper = ({ children, draggable, onDragEnd, droppableId }) => {
  if (draggable) {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId={droppableId}
          renderClone={(provided, snapshot, rubric) =>
            // The clone is rendered by the `rows` prop of the
            // PaginatedMiniList component. We can determine which
            // element is being dragged by looking at the `rubric` source index.
            children.props.rows[rubric.source.index].props.children(provided, snapshot)
          }
        >
          {(provided) => {
            return (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {Children.map(children, (child) =>
                  cloneElement(child, { placeholder: provided.placeholder })
                )}
              </div>
            )
          }}
        </Droppable>
      </DragDropContext>
    )
  }

  return <>{children}</>
}

const List = ({
  isLoading,
  isError,
  error,
  rows,
  meta,
  onPageChange,
  emptyMessage,
  borderless,
  hidePaginationIfSinglePage,
  placeholder = null,
}) => {
  const { t } = useTranslation()
  const isEmpty = useMemo(() => {
    return !isLoading && !isError && rows.length === 0
  }, [isLoading, isError, rows])

  return (
    <ul
      className={classNames(
        'text-sm overflow-hidden border-border rounded-lg divide-y divide-border',
        {
          'flex border-2 border-dashed min-h-[150px]': (isError || isEmpty) && !borderless,
          'bg-background': !(isError || isEmpty),
          'shadow-sm border': !(isError || isEmpty) && !borderless,
        }
      )}
    >
      {isLoading && (
        <>
          <LoadingRow />
          <LoadingRow />
          <LoadingRow />
          <LoadingRow />
          <LoadingRow />
        </>
      )}

      {isError && error && error.status !== 403 && (
        <div className="self-center text-center w-full text-lg font-medium text-gray-600">
          <ExclamationCircleIcon className="w-8 h-8 mb-2 mx-auto" aria-hidden="true" />
          {t('paginated_mini_list.error')}
        </div>
      )}

      {isError && error && error.status === 403 && (
        <div className="self-center text-center w-full text-lg font-medium text-gray-600">
          <ExclamationCircleIcon className="w-8 h-8 mb-2 mx-auto" aria-hidden="true" />
          {t('paginated_mini_list.missing_permissions_error')}
        </div>
      )}

      {!isLoading && !isError && rows.length === 0 && (
        <div className="self-center text-center w-full text-lg font-medium text-gray-600">
          {emptyMessage}
        </div>
      )}

      {!isLoading && !isError && rows.map((row) => row)}

      {placeholder}

      {!isEmpty &&
        !isError &&
        !isLoading &&
        meta &&
        (meta.last_page > 1 || !hidePaginationIfSinglePage) && (
          <div
            className={classNames(
              ' text-gray-600 dark:text-gray-400 text-xs p-4 py-3 flex justify-between items-center',
              {
                'bg-gray-50 dark:bg-transparent': !borderless,
              }
            )}
          >
            <span>
              {t('paginated_mini_list.meta', {
                total: meta.total,
                results: pluralize('result', meta.total),
                currentPage: meta.current_page,
                totalPages: meta.last_page,
              })}
            </span>
            <div className="flex space-x-2">
              <Button
                size="xs"
                disabled={meta.current_page === 1}
                leadingIcon={ChevronLeftIcon}
                label={t('paginated_mini_list.button.previous')}
                hideLabel
                onClick={() => onPageChange(meta.current_page - 1)}
              />

              <Button
                size="xs"
                disabled={meta.current_page === meta.last_page}
                leadingIcon={ChevronRightIcon}
                label={t('paginated_mini_list.button.next')}
                hideLabel
                onClick={() => onPageChange(meta.current_page + 1)}
              />
            </div>
          </div>
        )}
    </ul>
  )
}
