import { TYPE_APPOINTMENT, TYPE_APPOINTMENT_ENQUIRY } from 'constants/offeringTypes'
import {
  STATUS_CANCELLED,
  STATUS_PENDING_CONFIRMATION,
  STATUS_RESERVATION_EXPIRED,
  STATUS_RESERVED,
  STATUS_SETTLED,
} from 'constants/orderStatuses'
import { useBookingOrderItem } from 'contexts/BookingOrderItem'
import { useBookingSummary } from 'contexts/BookingSummary'
import { useGuest } from 'contexts/Guest'
import { useCurrentOrder, useOrder, useReduxOrder } from 'contexts/Order'
import { usePackageItem } from 'contexts/PackageOrderItem'
import { chain, filter } from 'lodash'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  selectAppointmentItemsIds,
  selectPackageItemIdsForGuestId,
  selectGuestIds,
  selectPackageOrderItemsIds,
  selectAppointmentItemIdsForGuestId,
  selectBookingOrderItemIdsRequiringReservationForOrderId,
  selectPackageOrderItemIdsRequiringReservationForOrderId,
  selectActiveAppointmentItemsIds,
  selectSessionItemsIds,
  selectActiveSessionItemsIds,
  selectSessionItemIdsForGuestId,
  selectOrderItemsUnion,
  selectOrderItemsUnionForGuestId,
  selectActivePackageOrderItemsIds,
  selectActiveOrderItemsUnion,
} from 'selectors/OrdersSelectors'

export const useOrderId = () => {
  const { id } = useOrder()

  return id
}

export const useOrderRevenueDate = () => {
  const { booking_items_end_date: revenueDate } = useOrder()

  return revenueDate
}

export const useReduxOrderId = () => {
  const { id } = useReduxOrder()

  return id
}

export const useGuestId = () => useGuest()?.id ?? ''

export const useAllOrderItems = () => {
  const { order = {} } = useCurrentOrder()
  const {
    booking_items: bookingItems = [],
    package_items: packageItems = [],
    purchase_items: purchaseItems = [],
  } = order

  return [...bookingItems, ...packageItems, ...purchaseItems].sort(function (a, b) {
    return a.created_at.getTime() - b.created_at.getTime()
  })
}

export const useAllActiveOrderItems = () => {
  return useAllOrderItems().filter(({ status }) => status !== STATUS_CANCELLED)
}

export const useAppointmentItemIds = () => {
  return useSelector(selectAppointmentItemsIds(useOrderId()))
}

export const useOrderItemsUnion = () => {
  return useSelector(selectOrderItemsUnion(useOrderId()))
}

export const useActiveOrderItemsUnion = () => {
  return useSelector(selectActiveOrderItemsUnion(useOrderId()))
}

export const useOrderItemsUnionForGuest = () => {
  return useSelector(selectOrderItemsUnionForGuestId(useOrderId(), useGuestId()))
}

export const useActiveAppointmentItemIds = () => {
  return useSelector(selectActiveAppointmentItemsIds(useOrderId()))
}

export const useActiveAppointmentItems = () => {
  const { booking_items: bookingItems = [] } = useOrder()

  return chain(bookingItems)
    .filter(
      ({ item_type: type, status }) => type === TYPE_APPOINTMENT && status !== STATUS_CANCELLED
    )
    .value()
}

/**
 * @deprecated
 */
export const useAppointmentItemIdsForGuest = () => {
  return useSelector(selectAppointmentItemIdsForGuestId(useReduxOrderId(), useGuestId()))
}

/**
 * @deprecated
 */
export const usePackageOrderItemIds = () => {
  return useSelector(selectPackageOrderItemsIds(useReduxOrderId()))
}

/**
 * @deprecated
 */
export const useActivePackageOrderItemIds = () => {
  return useSelector(selectActivePackageOrderItemsIds(useReduxOrderId()))
}

export const useActivePackageOrderItems = () => {
  const { package_items: packageItems = [] } = useOrder()

  return chain(packageItems)
    .filter(({ status }) => status !== STATUS_CANCELLED)
    .value()
}

export const useOrderFromReactQueryHasReservedItems = () => {
  const reservedBookingItems = useReservedBookingItems()
  const reservedPackageItems = useReservedPackageItems()
  const reservedPurchaseItems = useReservedPurchaseItems()

  return (
    reservedBookingItems.length > 0 ||
    reservedPackageItems.length > 0 ||
    reservedPurchaseItems.length > 0
  )
}

export const useOrderHasOnlyPurchaseItems = () => {
  const {
    order: {
      purchase_items: purchaseItems = [],
      booking_items: bookingItems = [],
      package_items: packageItems = [],
    } = {},
  } = useCurrentOrder()

  return purchaseItems.length > 0 && bookingItems.length === 0 && packageItems.length === 0
}

export const useReservedPurchaseItems = () => {
  const { order: { purchase_items: purchaseItems = [] } = {} } = useCurrentOrder()

  return chain(purchaseItems)
    .filter(({ status }) => [STATUS_RESERVED, STATUS_RESERVATION_EXPIRED].includes(status))
    .value()
}

export const useReservedBookingItems = () => {
  const { order: { booking_items: bookingItems = [] } = {} } = useCurrentOrder()

  return chain(bookingItems)
    .filter(({ status }) =>
      [STATUS_RESERVED, STATUS_RESERVATION_EXPIRED, STATUS_PENDING_CONFIRMATION].includes(status)
    )
    .value()
}

export const useReservedPackageItems = () => {
  const { order: { package_items: packageItems = [] } = {} } = useCurrentOrder()

  return chain(packageItems)
    .map('package_items')
    .flatten()
    .filter(({ booking_summary: { status = '' } = {} }) =>
      [STATUS_RESERVED, STATUS_RESERVATION_EXPIRED].includes(status)
    )
    .value()
}

/**
 * @deprecated
 */
export const useGuestIds = () => {
  return useSelector(selectGuestIds(useReduxOrderId()))
}

export const useOrderStatus = () => {
  const { status } = useOrder()

  return status
}

export const useOrderStage = () => {
  const { stage } = useOrder()

  return stage
}

/**
 * @deprecated
 */
export const usePackageOrderItemIdsForGuest = () => {
  return useSelector(selectPackageItemIdsForGuestId(useReduxOrderId(), useGuestId()))
}

/**
 * @deprecated
 */
export const useHasGuestsLegacy = () => {
  const guestIds = useGuestIds()

  return useMemo(() => guestIds.length > 0, [guestIds.length])
}

export const useHasGuests = () => {
  const { guests = [] } = useOrder()

  return guests.length > 0
}

export const useBookingOrderItemStatus = () => {
  const { status, booking_reserved_until: reservedUntil } = useBookingOrderItem()

  const isReserved = DateTime.fromJSDate(reservedUntil).diffNow().milliseconds >= 0

  return useMemo(() => {
    switch (status) {
      case STATUS_RESERVED:
        return !isReserved ? STATUS_RESERVATION_EXPIRED : STATUS_RESERVED
      default:
        return status
    }
  }, [isReserved, status])
}

export const usePackageItemStatus = () => {
  const { reserved_until: reservedUntil } = usePackageItem()
  const { status } = useBookingSummary()

  const isReserved = DateTime.fromJSDate(reservedUntil).diffNow().milliseconds >= 0

  return useMemo(() => {
    switch (status) {
      case STATUS_RESERVED:
        return !isReserved ? STATUS_RESERVATION_EXPIRED : STATUS_RESERVED
      default:
        return status
    }
  }, [isReserved, status])
}

/**
 * @deprecated
 */
export const useOrderHasReservedItems = () => {
  const reservedBookingItems = useSelector(
    selectBookingOrderItemIdsRequiringReservationForOrderId(useReduxOrderId())
  )
  const reservedPackageItems = useSelector(
    selectPackageOrderItemIdsRequiringReservationForOrderId(useReduxOrderId())
  )

  return reservedBookingItems.length > 0 || reservedPackageItems.length > 0
}

/**
 * @deprecated
 */
export const useSessionItemIds = () => {
  return useSelector(selectSessionItemsIds(useReduxOrderId()))
}

/**
 * @deprecated
 */
export const useActiveSessionItemIds = () => {
  return useSelector(selectActiveSessionItemsIds(useReduxOrderId()))
}

/**
 * @deprecated
 */
export const useSessionItemIdsForGuest = () => {
  return useSelector(selectSessionItemIdsForGuestId(useReduxOrderId(), useGuestId()))
}

/**
 * @deprecated
 */
export const useReduxOrderCustomerDetails = () => {
  const {
    first_name: firstName = '',
    last_name: lastName = '',
    email = '',
    customer_id: customerId,
  } = useReduxOrder()

  const fullName = useMemo(
    () => (firstName || lastName ? filter([firstName, lastName]).join(' ') : undefined),
    [firstName, lastName]
  )

  return useMemo(
    () => ({
      id: customerId,
      first_name: firstName,
      last_name: lastName,
      full_name: fullName,
      email: email,
    }),
    [customerId, email, firstName, fullName, lastName]
  )
}

export const useAllActivePackageOrderItemDates = () => {
  const { order: { package_items: packageItems = [] } = {} } = useCurrentOrder()

  return chain(packageItems)
    .filter(({ status, date }) => status !== STATUS_CANCELLED && DateTime.fromJSDate(date).isValid)
    .map('date')
    .filter()
    .uniqBy((date) => date.getTime())
    .value()
}

export const useAllActiveBookingOrderItemDates = () => {
  const { order: { booking_items: bookingItems = [] } = {} } = useCurrentOrder()

  return chain(bookingItems)
    .filter(({ status, date }) => status !== STATUS_CANCELLED && DateTime.fromJSDate(date).isValid)
    .map('date')
    .filter()
    .uniqBy((date) => date.getTime())
    .value()
}

export const useAllActiveEnquiryDates = () => {
  const { order: { purchase_items: purchaseItems = [] } = {} } = useCurrentOrder()

  return chain(purchaseItems)
    .filter(
      ({ status, item_type: itemType }) =>
        status !== STATUS_CANCELLED && itemType === TYPE_APPOINTMENT_ENQUIRY
    )
    .map(({ item_configuration: itemConfiguration }) =>
      DateTime.fromISO(itemConfiguration.time_from).startOf('day').toJSDate()
    )
    .filter()
    .uniqBy((date) => date.getTime())
    .value()
}

export const useAllActiveOrderItemDates = () => {
  const bookingDates = useAllActiveBookingOrderItemDates()
  const packageDates = useAllActivePackageOrderItemDates()
  const enquiryDates = useAllActiveEnquiryDates()

  const dates = chain(bookingDates)
    .concat(packageDates)
    .concat(enquiryDates)
    .sort()
    .uniqBy((date) => date.getTime())
    .value()

  return dates
}

export const useOrderIsSettled = () => {
  const { data: { data: { status } = {} } = {} } = useCurrentOrder()

  return status === STATUS_SETTLED
}

export const useCanEditItem = (item) => {
  const itemIsCancelled = item.itemStatus === STATUS_CANCELLED
  const isOrderSettled = useOrderIsSettled()
  const isOrderLocked = useOrderIsLocked()

  return !itemIsCancelled && !isOrderSettled && !isOrderLocked
}

export const useOrderIsLocked = () => {
  const { is_locked: locked } = useCurrentOrder()

  return locked === true
}
