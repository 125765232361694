import React, { Fragment, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, DotsVerticalIcon } from '@heroicons/react/solid'
import { Button, ButtonSizes, ButtonVariants } from './Button'
import { usePopper } from 'react-popper'
import { Link } from 'react-router-dom'
import { createPortal } from 'react-dom'
import { classNames } from 'ui/utilities'
import { Badge } from './Badge'

interface DropdownProps {
  children: any
  buttonLabel?: string
  buttonLoading?: boolean
  buttonHideLabel?: boolean
  buttonVariant?: ButtonVariants
  buttonSize?: ButtonSizes
  buttonLeadingIcon?: (props: React.ComponentProps<'svg'>) => JSX.Element
  buttonChevronHidden?: boolean
  buttonClass?: string
  threeDots?: boolean
  aboveModal?: boolean
  avatar?: JSX.Element
  popperOffset?: [number, number]
}

export const Dropdown: React.FC<DropdownProps> = (props: DropdownProps) => {
  const [referenceElement, setReferenceElement] = useState<any>(null)
  const [popperElement, setPopperElement] = useState<any>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: ['bottom-start', 'bottom-end'],
        },
      },
      {
        name: 'offset',
        options: {
          offset: props.popperOffset ?? [0, 0],
        },
      },
    ],
  })

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          {props.threeDots && (
            <Menu.Button
              as="button"
              key={`is_open_${open}`}
              className={`text-gray-400 hover:text-gray-500 -m-2 p-2 rounded-full focus:outline-none focus:ring-2 focus:ring-violet ${
                open && 'ring-2 ring-violet'
              } ${props.buttonClass}`}
              onClick={(e) => e.stopPropagation()}
              ref={setReferenceElement}
              role="menu"
            >
              <span className="sr-only">Open options</span>
              <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          )}

          {props.avatar && (
            <Menu.Button
              as="button"
              key={`is_open_${open}`}
              className={`text-gray-400 hover:text-gray-500 -m-2 p-1 rounded-full focus:outline-none focus:ring-2 focus:ring-violet ${
                open && 'ring-2 ring-violet'
              } ${props.buttonClass}`}
              ref={setReferenceElement}
              role="menu"
            >
              {props.avatar}
            </Menu.Button>
          )}

          {!props.threeDots && !props.avatar && (
            <Menu.Button
              as={Button}
              key={`is_open_${open}`}
              className={props.buttonClass}
              ref={setReferenceElement}
              label={props.buttonLabel}
              hideLabel={props.buttonHideLabel}
              loading={props.buttonLoading}
              leadingIcon={props.buttonLeadingIcon ?? null}
              trailingIcon={!props.buttonChevronHidden ? ChevronDownIcon : null}
              variant={props.buttonVariant ?? 'default'}
              size={props.buttonSize}
            />
          )}

          {createPortal(
            <div
              ref={setPopperElement}
              style={styles.popper}
              className={`${props.aboveModal ? 'z-50' : 'z-20'} absolute w-56`}
              {...attributes.popper}
            >
              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className={
                    'mt-2 w-56 rounded-lg shadow-lg bg-background dark:bg-background-muted ring-1 ring-black ring-opacity-5 divide-y divide-border focus:outline-none'
                  }
                >
                  <div className="px-1 py-1">{props.children}</div>
                </Menu.Items>
              </Transition>
            </div>,
            document.getElementsByTagName('body')[0]
          )}
        </>
      )}
    </Menu>
  )
}

export const DropdownDivider = () => <hr className="my-1 -mx-1 border-t border-border" />

export const DropdownItem = ({
  onClick = null,
  icon,
  label,
  href = '',
  disabled = false,
  subtitle = undefined,
  inAppLink = false,
  hideOnClick = true,
  variant = undefined,
  beta = false,
}) => (
  <Menu.Item disabled={disabled}>
    {({ active }) =>
      onClick || disabled ? (
        <button
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.stopPropagation()

            if (!hideOnClick) {
              e.preventDefault()
            }

            onClick()
          }}
          disabled={disabled}
          className={classNames(
            disabled ? 'bg-gray-50 opacity-75 cursor-not-allowed' : '',
            variant === 'danger' ? 'text-red-500' : '',
            active && !disabled && !variant
              ? 'text-gray-900 bg-gray-100  dark:bg-gray-700 dark:text-gray-300'
              : 'text-gray-700 dark:text-gray-300',
            'group flex items-center p-2 px-3 text-sm rounded-md w-full'
          )}
        >
          {icon &&
            icon({
              className: classNames(
                'flex-shrink-0 mr-3 h-5 w-5 text-gray-400',
                variant === 'danger' ? 'text-red-500 group-hover:text-red-500' : '',
                !disabled && !variant ? 'group-hover:text-gray-500' : ''
              ),
              'aria-hidden': true,
            })}

          <div className="text-left">
            <div>
              <span>{label}</span>
              {beta && <Badge label="Beta" variant="info" />}
              {subtitle !== undefined && (
                <span className="text-xs text-gray-500 block">{subtitle}</span>
              )}
            </div>
          </div>
        </button>
      ) : inAppLink ? (
        <Link
          to={href}
          title={label}
          className={classNames(
            disabled ? 'bg-gray-50 text-gray-500 cursor-not-allowed' : '',
            active && !disabled
              ? 'bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-gray-300'
              : 'text-gray-700 dark:text-gray-300',
            'group flex items-center p-2 px-3 text-sm rounded-lg w-full'
          )}
          rel="noreferrer"
        >
          {icon &&
            icon({
              className: `flex-shrink-0 mr-3 h-5 w-5 text-gray-400 ${
                !disabled ? 'group-hover:text-gray-500' : ''
              }`,
              'aria-hidden': true,
            })}

          <div className="text-left">
            <span>{label}</span>

            {subtitle && <span className="text-xs text-gray-500 block">{subtitle}</span>}
          </div>
        </Link>
      ) : (
        <a
          href={href}
          title={label}
          target="_blank"
          className={classNames(
            disabled ? 'bg-gray-50 text-gray-500 cursor-not-allowed' : '',
            active && !disabled
              ? 'bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-gray-300'
              : 'text-muted-foreground dark:text-gray-300',
            'group flex items-top p-2 px-3 text-sm rounded-lg w-full'
          )}
          rel="noreferrer"
        >
          {icon &&
            icon({
              className: `flex-shrink-0 mr-3 h-5 w-5 text-gray-400 ${
                !disabled ? 'group-hover:text-gray-500' : ''
              }`,
              'aria-hidden': true,
            })}

          <div className="text-left">
            <span>{label}</span>

            {subtitle && <span className="text-xs text-gray-500 block">{subtitle}</span>}
          </div>
        </a>
      )
    }
  </Menu.Item>
)
