import { STATUS_RESERVATION_EXPIRED, STATUS_RESERVED } from 'constants/orderStatuses'
import { Col } from '@trybeapp/ui.grid'
import { DateTime } from 'luxon'
import { StatusTag } from './Overview'
import { useState } from 'react'
import { useCurrentOrder } from 'contexts/Order'
import { TagIcon, XIcon } from '@heroicons/react/solid'
import { RemoveDiscountFromItem } from '../Discounts/RemoveOrderItemDiscountConfirmation'
import { minutesToFriendlyDuration } from 'utilities/DateUtils/dateUtils'
import { useTranslation } from 'react-i18next'
import { formatMoney } from 'ui/components/Money'
import { Text } from 'ui/components/Text'
import { useActiveFeatureFlags } from 'contexts/SiteConfig'

export const StatusColumn = ({ item }) => {
  const { booking_reserved_until: reservedUntil, status: itemStatus } = item
  const reservedUntilObj = DateTime.fromJSDate(reservedUntil)
  const isStillReserved = reservedUntilObj.diffNow().valueOf() > 0
  const reservable = itemStatus === STATUS_RESERVED

  const displayStatus =
    itemStatus === STATUS_RESERVED && !isStillReserved ? STATUS_RESERVATION_EXPIRED : itemStatus

  return (
    <Col col={0.13}>
      <StatusTag status={displayStatus} />
      {reservable && isStillReserved && (
        <Text type="caption2">Until {reservedUntilObj.toLocaleString(DateTime.TIME_SIMPLE)}</Text>
      )}
    </Col>
  )
}

export const DateColumn = ({ item }) => {
  const { booking_summary: bookingSummary = {} } = item
  const { start_time: startTime } = bookingSummary

  return (
    <Col col={0.15}>
      <span className="text-sm">{DateTime.fromJSDate(startTime).toLocaleString()}</span>
    </Col>
  )
}

export const TypeColumn = ({ item, children }) => {
  const { type_name: typeName } = item

  return (
    <Col col={0.3}>
      <span className="text-sm">{typeName}</span>
      {children}
    </Col>
  )
}

export const CostColumn = ({ item }) => {
  const { order: { id: orderId, currency, applied_promo_code_id: appliedPromoCodeId } = {} } =
    useCurrentOrder()
  const featureFlags = useActiveFeatureFlags()

  const {
    id: itemId,
    total_cost: totalCost,
    exclusive_tax_amount: exclusiveTaxAmount,
    discounts = [],
  } = item

  const displayTotal = featureFlags.includes('apply_service_charge_to_price')
    ? totalCost - exclusiveTaxAmount
    : totalCost

  const [removeDiscountConfirmation, setRemoveDiscountConfirmation] = useState(null)

  const canDeleteDiscount = (discount) => {
    return discount.reason_code !== 'voucher_difference'
  }

  return (
    <Col col={0.2}>
      <span className="text-sm">{formatMoney({ amount: displayTotal, currency })}</span>
      {discounts.map((discount) => (
        <div className="text-blue-500 text-sm flex items-center gap-x-1" key={discount.id}>
          <span>inc {formatMoney({ amount: discount.calculated_amount, currency })} discount</span>
          {canDeleteDiscount(discount) && appliedPromoCodeId === null && (
            <div className="flex items-center">
              <button>
                <XIcon
                  className="h-3 w-3 cursor-pointer"
                  onClick={() => setRemoveDiscountConfirmation(discount)}
                />
              </button>
              <RemoveDiscountFromItem
                discount={removeDiscountConfirmation}
                orderId={orderId}
                itemId={itemId}
                onClose={() => setRemoveDiscountConfirmation(null)}
              />
            </div>
          )}
          {discount.reason_code === 'voucher_difference' && <TagIcon className="h-3 w-3" />}
        </div>
      ))}
    </Col>
  )
}

export const StartTimeColumn = ({ item }) => {
  const { t } = useTranslation()
  const { booking_summary: bookingSummary = {} } = item
  const { start_time: startTime, duration } = bookingSummary

  return (
    <Col col={0.12}>
      <div className="flex flex-col">
        <span className="text-sm">
          {DateTime.fromJSDate(startTime).toLocaleString(DateTime.TIME_SIMPLE)}
        </span>
        <Text type="caption">{minutesToFriendlyDuration(duration, t)}</Text>
      </div>
    </Col>
  )
}
