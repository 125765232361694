import { STATUS_RESERVATION_EXPIRED, STATUS_RESERVED } from 'constants/orderStatuses'
import { Col } from '@trybeapp/ui.grid'
import { DateTime } from 'luxon'
import { StatusTag } from './Overview'
import { useState } from 'react'
import { useCurrentOrder } from 'contexts/Order'
import { XIcon } from '@heroicons/react/solid'
import { RemoveDiscountFromItem } from '../Discounts/RemoveOrderItemDiscountConfirmation'
import { Text } from 'ui/components/Text'
import { formatMoney } from 'ui/components/Money'
import { minutesToFriendlyDuration } from 'utilities/DateUtils/dateUtils'
import { useTranslation } from 'react-i18next'

export const StatusColumn = ({ item, ...props }) => {
  const { reserved_until: reservedUntil, booking_summary: bookingSummary = {} } = item
  const { status } = bookingSummary

  const reservedUntilObj = DateTime.fromJSDate(reservedUntil)
  const isStillReserved = reservedUntilObj.diffNow().valueOf() > 0
  const reservable = status === STATUS_RESERVED

  const displayStatus =
    status === STATUS_RESERVED && !isStillReserved ? STATUS_RESERVATION_EXPIRED : status

  return (
    <Col col={0.13} {...props}>
      <StatusTag status={displayStatus} />
      {reservable && isStillReserved && (
        <Text type="caption">Until {reservedUntilObj.toLocaleString(DateTime.TIME_SIMPLE)}</Text>
      )}
    </Col>
  )
}

export const DateColumn = ({ item }) => {
  const { booking_summary: bookingSummary = {} } = item
  const { start_time: startTime } = bookingSummary

  return (
    <Col col={0.15}>
      <span className="text-sm">{DateTime.fromJSDate(startTime).toLocaleString()}</span>
    </Col>
  )
}

export const PackageItemTypeColumn = ({ item, children }) => {
  const { offering_name: offeringName } = item

  return (
    <Col col={0.35}>
      <span className="text-sm">{offeringName}</span>
      {children}
    </Col>
  )
}

export const CostColumn = ({ item }) => {
  const { order: { id: orderId, currency, applied_promo_code_id: appliedPromoCodeId } = {} } =
    useCurrentOrder()
  const { id: itemId, total_cost: totalCost, discounts = [] } = item
  const [removeDiscountConfirmation, setRemoveDiscountConfirmation] = useState(null)

  const canRemoveDiscount = (discount) => {
    return discount.reason_code !== 'voucher_difference'
  }

  return (
    <Col col={0.2}>
      <span className="text-sm">{formatMoney({ amount: totalCost, currency })}</span>
      {discounts.map((discount) => (
        <div className="text-blue-500 text-sm flex items-center gap-x-1" key={discount.id}>
          inc{' '}
          <span>inc {formatMoney({ amount: discount.calculated_amount, currency })} discount</span>
          {canRemoveDiscount(discount) && appliedPromoCodeId === null && (
            <>
              <XIcon
                className="h-3 w-3 cursor-pointer"
                onClick={() => setRemoveDiscountConfirmation(discount)}
              />
              <RemoveDiscountFromItem
                discount={removeDiscountConfirmation}
                orderId={orderId}
                itemId={itemId}
                onClose={() => setRemoveDiscountConfirmation(null)}
              />
            </>
          )}
        </div>
      ))}
    </Col>
  )
}

export const StartTimeColumn = ({ item }) => {
  const { t } = useTranslation()
  const { booking_summary: bookingSummary = {} } = item
  const { start_time: startTime, duration } = bookingSummary

  return (
    <Col col={0.12}>
      <div className="flex flex-col">
        <span className="text-sm">
          {DateTime.fromJSDate(startTime).toLocaleString(DateTime.TIME_SIMPLE)}
        </span>
        <Text type="caption">{minutesToFriendlyDuration(duration, t)}</Text>
      </div>
    </Col>
  )
}
