import { useParams } from 'react-router-dom'
import { Box } from '@trybeapp/ui.box'
import { Text } from '@trybeapp/ui.text'
import { Col, Row } from '@trybeapp/ui.grid'
import { CostColumn, DateColumn, StartTimeColumn, StatusColumn, TypeColumn } from '../Columns'
import { Card } from '../../../components/Card'
import { HeadingsRow } from './HeadingsRow'
import { BookingOrderItemResourceSummary } from './AppointmentItemRow'
import { useCanEditItem } from '../hooks'
import { ItemActions } from './ItemActions'
import { useDeleteOrderItem } from 'api/OrderItems'
import { DropdownItem } from 'ui/components/Dropdown'
import { PencilIcon } from '@heroicons/react/outline'
import { useActiveFeatureFlags } from 'contexts/SiteConfig'
import { useOrderDetails } from 'screens/OrderDetails/contexts'
import { useSessionType } from 'api/SessionTypes'

export const SessionItemRow = ({ item }) => {
  const { orderId } = useParams()
  const { id: itemId, type_name: name = '', type_id: typeId, status: itemStatus } = item
  const { data: { data: { product_code: code } = {} } = {} } = useSessionType(typeId)

  const canEdit = useCanEditItem(item)
  const featureFlags = useActiveFeatureFlags()

  const { mutate, isLoading: isDeleting } = useDeleteOrderItem()
  const handleDelete = () => {
    mutate([orderId, itemId])
  }

  return (
    <Card className="my-2">
      <Card.Body>
        <Row>
          <Col col={0.7} mb="lg">
            <Box display="flex" alignItems="center">
              {code && (
                <Text as="span" variant="body3" color="nude.700" mr="xs">
                  <strong>[{code}]</strong>
                </Text>
              )}
              <Text as="span" variant="body3" color="nude.700">
                <strong>{name}</strong>
              </Text>
            </Box>
          </Col>
        </Row>
        <HeadingsRow />
        <Row>
          <StatusColumn item={item} />
          <DateColumn item={item} />
          <StartTimeColumn item={item} />
          <TypeColumn item={item}>
            <BookingOrderItemResourceSummary item={item} />
          </TypeColumn>
          <CostColumn item={item} />
          <Col col={0.1}>
            <ItemActions item={item} handleDelete={handleDelete} isDeleting={isDeleting}>
              {canEdit && <EditItemButton item={item} />}
            </ItemActions>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

const EditItemButton = ({ item }) => {
  const { setValue } = useOrderDetails()

  const handleOnClick = () => {
    setValue('sessionWizard.editingSession', item)
  }

  return <DropdownItem label="Edit" onClick={handleOnClick} icon={PencilIcon} />
}
