import React from 'react'
import { Link } from 'react-router-dom'
import { Text } from './Text'
import { Card } from './Card'
import { Tooltip } from 'react-tooltip'

interface FeatureCardProps {
  title: string | JSX.Element
  subtitle?: string
  truncateTitle?: boolean
  iconForeground?: string
  iconBackground?: string
  icon?: (_: any) => React.ReactNode
  image?: string
  links?: any[]
  children?: any
  isLinked?: boolean
  className?: string
  disabled?: boolean
  disabledReason?: string
}

interface LinkedFeatureCardProps extends FeatureCardProps {
  to: string
}

export const LinkedFeatureCard: React.FC<LinkedFeatureCardProps> = (props) =>
  props.disabled ? (
    <div
      data-tooltip-content={props.disabledReason}
      data-tooltip-id="disabled-tooltip"
      data-tooltip-place="top"
    >
      <FeatureCard
        {...props}
        className="opacity-50 cursor-not-allowed text-muted-foreground hover:border-violet"
      />
      <Tooltip id="disabled-tooltip" style={{ backgroundColor: 'rgb(153 153 255)' }} />
    </div>
  ) : (
    <Link to={props.to} className="flex-grow focus:ring-1 focus:ring-violet">
      <FeatureCard {...props} className="hover:border-violet" isLinked />
    </Link>
  )

export const FeatureCard: React.FC<FeatureCardProps> = ({
  title,
  iconForeground,
  iconBackground,
  icon = null,
  image = null,
  links = [],
  children = null,
  className = '',
}) => (
  <Card className={`h-full w-full flex flex-col ${className}`}>
    <div className={`flex space-x-2 items-center`}>
      {icon && (
        <div
          className={`rounded-lg p-1.5 ring-4 ring-background ${iconBackground} ${iconForeground}`}
        >
          {icon({
            className: 'w-4 h-4',
          })}
        </div>
      )}
      {image && (
        <img src={image} alt="" className="w-8 h-8 rounded-lg bg-gray-100 dark:bg-gray-800" />
      )}
      <div>
        <Text type="headline" className="truncate">
          {title}
        </Text>
      </div>
    </div>

    <div className="space-y-4 flex-1">
      {children && (
        <Text type="caption" className="h-full">
          {children}
        </Text>
      )}

      {links.length > 0 && (
        <ul className="pt-2 font-medium space-y-2">
          {links
            .filter((link) => !!link)
            .map((link) => (
              <li key={link.to}>
                <Link className="hover:underline" to={link.to}>
                  {link.label}
                </Link>
              </li>
            ))}
        </ul>
      )}
    </div>
  </Card>
)
