import { MailIcon, PhoneIcon } from '@heroicons/react/solid'
import { useGetCustomer } from 'api/Customers'
import { useParams } from 'react-router-dom'
import { Button } from 'ui/components/Button'
import { useNotificationContext, Notification } from 'ui/components/Notification'
import { useCheckIn } from 'api/CheckIns'
import { useSiteConfig } from 'hooks/UseSiteConfig'
import { LinkWithPreload } from 'utilities/PreloadLinks'
import { useState, useEffect } from 'react'
import { Details } from './Overview/Details'
import { Addresses } from './Addresses/Addresses'
import { Credits } from './Overview/Credits'
import { Memberships } from './Memberships/Memberships'
import { CheckIcon, CogIcon } from '@heroicons/react/solid'
import { Notes } from './Notes/Notes'
import { Popover } from '@headlessui/react'
import { useLabels } from 'api/Labels'
import { useAttachCustomerLabel, useDetatchCustomerLabel } from 'api/CustomerLabels'
import { Spinner } from 'ui/components/Spinner'
import { MarketingPreferences } from './MarketingPreferences/MarketingPreferences'
import { PaymentMethods } from './PaymentMethods'
import { ClientHeader } from './ClientHeader'
import { Orders } from './Orders/Orders'
import { IntakeForms } from './Overview/IntakeForms'
import { useActiveFeatureFlags } from 'contexts/SiteConfig'
import DetailsScreen from 'ui/components/DetailsScreen'
import { ClientAvatarWithEdit } from './ClientAvatar'
import { AuditLogs } from './AuditLogs'
import { Section } from './Shared/Section'
import { Stats } from './Overview/Stats'
import { useCurrentUserCan } from 'hooks/UseCurrentUserCan'
import { CUSTOMERS_MANAGE } from 'constants/permissions'

const Labels = ({ labels, clientId, onChange }) => {
  const can = useCurrentUserCan()

  const { data: { data: allLabels = [] } = {} } = useLabels({ perPage: 100 })
  const { mutate: attach, isLoading: isAttaching } = useAttachCustomerLabel(clientId)

  const { mutate: detach, isLoading: isDetaching } = useDetatchCustomerLabel(clientId)

  const selectedIds = (labels ?? []).map((label) => label.id)

  const addLabel = (label) => {
    onChange(labels.concat(label))
    attach(label.id)
  }

  const removeLabel = (label) => {
    onChange(labels.filter((selected) => selected.id !== label.id))
    detach(label.id)
  }

  const handleLabelToggle = (label) => {
    if (selectedIds.includes(label.id)) {
      removeLabel(label)
    } else {
      addLabel(label)
    }
  }

  // If the site hasn't got any labels set up, don't render anything.
  if (!allLabels.length) {
    return null
  }

  return (
    <div className="text-xs font-medium space-x-2 flex px-4 sm:px-6 max-w-7xl mx-auto">
      <ul className="flex space-x-2 transition-width">
        {labels.map((label) => (
          <li
            className="rounded-b-lg py-0.5 px-2 lg:py-1 lg:px-3 text-white"
            style={{ backgroundColor: '#' + label.colour }}
            key={label.id}
          >
            {label.value}
          </li>
        ))}
      </ul>

      {/*
       * TODO: If Headless UI ever releases a multi-select Listbox, replace this with that instead.
       * It'll be better for accessibility.
       */}
      {/* TEMP: hiding for TRY-9575 - will be updated to be a read-only input for unauthorised users */}
      {can(CUSTOMERS_MANAGE) && (
        <Popover className="relative">
          <Popover.Button className="text-gray-700 hover:text-gray-500 focus:outline-none border-2 border-t-0 border-violet-200 focus:border-violet rounded-b-lg py-0.5 px-2 lg:py-1 lg:px-3 flex items-center">
            {(isAttaching || isDetaching) && (
              <div className="-my-2">
                <Spinner height="h-4" width="w-4" margin="mr-1" />
              </div>
            )}

            {!isAttaching && !isDetaching && (
              <CogIcon className="w-4 h-4 mr-1" aria-hidden="true" />
            )}

            <span>Manage labels</span>
          </Popover.Button>

          <Popover.Panel className="absolute mt-2 w-56 rounded-lg z-10 shadow-lg bg-background p-1 ring-1 ring-black ring-opacity-5 overflow-y-auto max-h-80">
            <ul className="space-y-2">
              {allLabels.map((label) => (
                <li key={`allLabels_${label.id}`}>
                  <button
                    className="text-gray-700 group flex items-center space-x-2 p-2 px-3 text-sm rounded-lg w-full hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-violet"
                    onClick={() => handleLabelToggle(label)}
                  >
                    <div
                      className="w-4 h-4 rounded-full flex-shrink-0"
                      style={{ backgroundColor: '#' + label.colour }}
                    />
                    <span className="flex-1 text-left truncate">{label.value}</span>
                    {selectedIds.includes(label.id) && (
                      <div className="w-5 h-5 flex-shrink-0">
                        <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        <span className="sr-only">Selected</span>
                      </div>
                    )}
                  </button>
                </li>
              ))}
            </ul>
          </Popover.Panel>
        </Popover>
      )}
    </div>
  )
}

export const CheckInButton = ({ client }) => {
  const { siteId } = useSiteConfig()
  const notification = useNotificationContext()

  const { isLoading, mutate } = useCheckIn(siteId)

  const handleClick = () => {
    mutate(
      [
        client.id,
        {
          method_id: 'check_in_button',
        },
      ],
      {
        onSuccess: () => {
          notification.notify(
            <Notification
              title="Checked in"
              description={`${client.full_name} was succesfully checked in.`}
              variant="success"
              autoDismiss
            />
          )
        },
        onError: (e: any) => {
          if (e.status === 400) {
            notification.notify(
              <Notification
                title="Already checked in"
                description={`${client.full_name} has already been checked in less than 5 minutes ago.`}
                variant="warning"
                autoDismiss
              />
            )
          } else {
            notification.notify(
              <Notification
                title="Failed to check in"
                description={`${client.full_name} couldn't be checked in right now, please try again later.`}
                variant="danger"
                autoDismiss
              />
            )
          }
        },
      }
    )
  }

  return <Button variant="primary" label="Check in" onClick={handleClick} loading={isLoading} />
}

export const ClientMetadata = ({ client }) => (
  <div className="space-y-1 text-sm text-gray-500 mt-1">
    <div className="flex items-center space-x-1">
      <MailIcon className="w-5 h-5" />
      {client.email && <span>{client.email}</span>}

      {!client.email && <span className="italic">No email</span>}
    </div>
    <div className="flex items-center space-x-1">
      <PhoneIcon className="w-5 h-5" />
      {client.phone && <span>{client.phone}</span>}

      {!client.phone && <span className="italic">No phone</span>}
    </div>
  </div>
)

const ClientDetail = () => {
  const featureFlags = useActiveFeatureFlags()
  const { clientId } = useParams()
  const {
    isLoading,
    isError,
    error,
    data: { data: client = {} } = {},
  } = useGetCustomer(clientId, {
    enabled: clientId !== null,
  })

  const [customerLabels, setCustomerLabels] = useState(client?.labels ?? [])

  useEffect(() => {
    setCustomerLabels(client?.labels ?? [])
  }, [client.labels])

  const isNotFound = isError && (error as any).status === 404

  if (!clientId) {
    return null
  }

  if (isError && isNotFound) {
    return (
      <div className="bg-violet-50 px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="max-w-max mx-auto text-center">
          <h1 className="text-4xl font-extrabold mb-0 text-gray-700 tracking-tight sm:text-5xl">
            Client not found
          </h1>
          <p className="text-base text-gray-500 py-4">Please check the URL is correct</p>

          <LinkWithPreload to="/clients">
            <Button label="Back to clients" />
          </LinkWithPreload>
        </div>
      </div>
    )
  }

  if (isError) {
    return (
      <div className="bg-violet-50 px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="max-w-max mx-auto text-center">
          <h1 className="text-4xl font-extrabold mb-0 text-gray-700 tracking-tight sm:text-5xl">
            Error loading client
          </h1>
          <p className="text-base text-gray-500 py-4">
            An error occurred when loading this client. Please refresh to try again
          </p>
        </div>
      </div>
    )
  }

  return (
    <DetailsScreen
      title={client?.full_name ?? 'Unnamed client'}
      avatar={
        <ClientAvatarWithEdit avatarUrl={client.avatar?.url} isLocked={client.locked_at !== null} />
      }
      buttons={[<ClientHeader isLoading={isLoading} client={client} key="header" />]}
      isLoading={isLoading}
      subtitle={[
        {
          icon: MailIcon,
          label: 'Email',
          text: client.email ?? 'No email',
        },
        {
          icon: PhoneIcon,
          label: 'Phone',
          text: client.phone ?? 'No phone',
        },
      ]}
      previousPages={[
        {
          title: 'Clients',
          path: featureFlags.includes('new_client_list') ? '/beta/clients' : '/clients',
        },
      ]}
      notesAndAudit={
        <>
          <Notes />
          <Section title="Audit">
            <AuditLogs subjectId={client.id} />
          </Section>
        </>
      }
      labels={<Labels labels={customerLabels} clientId={client.id} onChange={setCustomerLabels} />}
    >
      <Details />
      <Stats />
      <div className="divide-y divide-border space-y-8">
        <Memberships />
        <Orders />
        <IntakeForms />
        <Addresses />
        <PaymentMethods />
        <Credits />
        <MarketingPreferences />
      </div>
    </DetailsScreen>
  )
}

export default ClientDetail
