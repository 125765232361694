import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
  UseMutationResult,
} from 'react-query'
import { CreateVisitGuestRequest, UpdateVisitRequest, VisitsApi } from '@trybeapp/sdk'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import { apiConfiguration, formatDateForApi } from 'utilities/TrybeApiClient'
import { toIsoDate } from 'utilities/DateUtils/dateUtils'

const api = new VisitsApi(apiConfiguration)

export const useVisits = (params: any = {}, options: UseQueryOptions = {}): UseQueryResult<any> => {
  params.siteId = useCurrentSiteId()
  const formattedParams = formatDateParams(params)

  return useQuery(['visits', formattedParams], () => api.getVisits(formattedParams), options)
}

export const useVisitSmartFilters = () => {
  const siteId = useCurrentSiteId()

  return useQuery(['visitSmartFilters', { siteId }], () => api.getVisitsSmartFilters({ siteId }))
}

export const useVisit = (visitId: string, options = {}) => {
  return useQuery(
    ['visit', visitId],
    () =>
      api.getVisit({
        visitId,
      }),
    options
  )
}

export const useCreateVisit = (): UseMutationResult<any, any> => {
  const siteId = useCurrentSiteId()

  return useMutation((visit: any) => {
    visit.arrival_date = toIsoDate(visit.arrival_date)
    visit.departure_date = toIsoDate(visit.departure_date)

    return api.createNewVisit({
      siteId,
      createVisitRequest: visit,
    })
  })
}

export const useUpdateVisit = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ([visitId, visit]: [string, UpdateVisitRequest]) => {
      visit.arrival_date = toIsoDate(visit.arrival_date)
      visit.departure_date = toIsoDate(visit.departure_date)

      return api.updateVisit({
        visitId,
        updateVisitRequest: visit,
      })
    },
    {
      onSuccess: (data, [visitId, visit]) => {
        // Invalidate the specific visit cache
        queryClient.invalidateQueries(['visit', visitId])
        queryClient.invalidateQueries(['visits'])
      },
    }
  )
}

export const useDeleteVisit = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (visitId: string) =>
      api.deleteVisit({
        visitId,
      }),
    {
      onSuccess: (_, visitId) => {
        queryClient.invalidateQueries(['visit'])
        queryClient.invalidateQueries(['visit', visitId])
      },
    }
  )
}

export const useSendVisitNotification = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (visitId: string) => {
      return api.actionSendVisitNotification({
        visitId,
      })
    },
    {
      onSuccess: (_, visitId) => {
        queryClient.invalidateQueries(['visit'])
        queryClient.invalidateQueries(['visit', visitId])
      },
    }
  )
}

export const useCreateVisitGuest = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ([visitId, visitGuest]: [string, CreateVisitGuestRequest]) =>
      api.createVisitGuest({
        visitId,
        createVisitGuestRequest: visitGuest,
      }),
    {
      onSuccess: (_, visitId) => {
        queryClient.invalidateQueries(['visit'])
        queryClient.invalidateQueries(['visit', visitId])
      },
    }
  )
}

export const useDeleteVisitGuest = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ([visitId, guestId]: [string, string]) =>
      api.deleteVisitGuest({
        visitId,
        guestId,
      }),
    {
      onSuccess: (_, visitId) => {
        queryClient.invalidateQueries(['visit'])
        queryClient.invalidateQueries(['visit', visitId])
      },
    }
  )
}

const formatDateParams = (params: any) => {
  return Object.keys(params).reduce((formattedParams, key) => {
    if (['createdAtDateFrom', 'createdAtDateTo'].includes(key)) {
      formattedParams[key] = formatDateForApi(params[key])
    } else {
      formattedParams[key] = params[key]
    }
    return formattedParams
  }, {})
}
