import React, { useEffect, useMemo } from 'react'
import { PreviousPage } from '../ScreenLayout'
import { useSidebar } from 'layouts/SidebarLayout/contexts'
import { Text } from '../Text'
import classNames from 'classnames'

interface DetailsScreenProps {
  title: string
  isLoading?: boolean
  badge?: React.ReactNode
  subtitle: {
    icon: (props: React.ComponentProps<'svg'>) => JSX.Element
    label: string
    text: string | React.ReactNode
  }[]
  buttons: React.ReactNode
  previousPages: PreviousPage[]
  avatar?: React.ReactNode
  labels?: React.ReactNode
  notesAndAudit?: React.ReactNode
  children: React.ReactNode
}

interface DetailsScreenContainerProps {
  noPadding?: boolean
  children: React.ReactNode
}

interface DetailsScreenHeaderProps {
  background?: string
  children: React.ReactNode
}

export const DetailsScreen: React.FC<DetailsScreenProps> = ({
  title,
  subtitle = undefined,
  isLoading = false,
  buttons,
  notesAndAudit,
  previousPages = [],
  avatar = null,
  labels = null,
  noPadding = false,
  children,
  badge,
}) => {
  const { setTitle, setPreviousPages } = useSidebar()
  const jsonifiedPreviousPages = useMemo(() => JSON.stringify(previousPages), [previousPages])

  useEffect(() => {
    if (typeof setPreviousPages === 'function') {
      setTitle(title)
      setPreviousPages(previousPages)
    }
  }, [setTitle, title, setPreviousPages, jsonifiedPreviousPages])

  return (
    <DetailsScreenContainer noPadding={noPadding}>
      <DetailsScreenHeader>
        <div className="px-4 sm:px-6 lg:px-8 sm:flex sm:items-end sm:space-x-5 pt-2 pb-6">
          <div className="2xl:block min-w-0 flex-1">
            <div className="flex items-center space-x-4 mt-4">
              <div>
                {isLoading && (
                  <div className="flex items-center space-x-6">
                    {avatar && <div className="w-[75px] h-[75px] bg-gray-200 rounded-full" />}
                    <div>
                      <div className="animate-pulse bg-gray-200 w-48 h-8 relative" />
                      <div className="space-y-1 text-sm text-gray-500 mt-1">
                        {subtitle.map((item, i) => (
                          <div className="flex items-center space-x-1" key={i}>
                            <item.icon className="w-5 h-5" />
                            <div className="animate-pulse bg-gray-200 w-24 h-4" />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

                {!isLoading && (
                  <div className="flex items-center space-x-6">
                    {avatar && avatar}
                    <div>
                      <div className="flex items-center space-x-2">
                        <Text type="title3">{title}</Text>
                        {badge}
                      </div>
                      <div className="space-y-1 text-sm text-gray-500 mt-1">
                        {subtitle.map((item, i) => (
                          <div className="flex items-center space-x-1" key={i}>
                            <item.icon className="w-5 h-5" />
                            <div className="font-medium">{item.text}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
            <div className="flex space-x-2 items-center">{!isLoading && buttons}</div>
          </div>
        </div>
      </DetailsScreenHeader>

      {labels && <div className="px-4 max-w-7xl mx-auto">{labels}</div>}

      <div className="mt-6 px-4 sm:px-6 max-w-7xl mx-auto">
        <div className="lg:flex lg:divide-x lg:divide-border">
          <div className="flex-1 lg:pr-8 space-y-8 min-w-0">{children}</div>

          <div className="w-full lg:w-72 space-y-8">{notesAndAudit}</div>
        </div>
      </div>
    </DetailsScreenContainer>
  )
}

export const DetailsScreenContainer: React.FC<DetailsScreenContainerProps> = ({
  noPadding = false,
  children,
}) => (
  <article className={`bg-background min-h-screen style-reset ${noPadding ? '' : 'pb-8'}`}>
    {children}
  </article>
)

export const DetailsScreenHeader: React.FC<DetailsScreenHeaderProps> = ({
  background,
  children,
}) => {
  const classes = classNames(
    {
      'bg-background-muted': !background,
      'bg-background': background === 'background',
    },
    'border-b border-border pt-2 pb-6'
  )

  return (
    <div className={classes}>
      <div className="max-w-7xl mx-auto">{children}</div>
    </div>
  )
}

export default DetailsScreen
